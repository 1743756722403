export default function Footer() {
  return (
    <>
      <div className="border-t-[1px] border-solid border-[#D3D3DF] py-[20px] px-[10px]">
        <div className="flex justify-center gap-[70px] tablet:gap-0 tablet:justify-between items-center unwrap">
          <div className="text-center">
            <a href="https://higher-re.imweb.me" target="_blank" className="cursor-pointer hover:underline">기업소개</a>
          </div>
          <div className="text-center">
            <a href="https://higher-re.notion.site/46c5e51766d24d1187a49606e76e85bb?pvs=4" target="_blank" className="cursor-pointer hover:underline">이용약관</a>
          </div>
          <div className="text-center">
            <a href="https://higher-re.notion.site/74b01b8cbe05492caa1c66690667c078?pvs=4" target="_blank"  className="cursor-pointer hover:underline">개인정보 처리방침</a>
          </div>
        </div>
        <div className="flex w-[1000px] tablet:w-[100%] items-center justify-between py-[10px]">
          <div className="pr-[10px] py-[10px]">
            <img src="/images/HIGHER-re-footer.svg" />
          </div>
          <div className="text-[8px] text-[#B0B0BD]">
            {'ⓒ 2023. HIGHER Co. All rights reserved.'}
          </div>
        </div>
        <div className="text-[12px] text-[#B0B0BD]">
          하이어 (대표: 정민서) | 서울특별시 관악구 낙성대로 2, 낙성벤처창업센터 B1
        </div>
        <div className="text-[12px] text-[#B0B0BD]">
          사업자 등록번호 : 406-06-53503 | 통신판매번호: 2023-성남분당A-0971호{' '}
        </div>
        <div className="text-[12px] text-[#B0B0BD]">
        직업정보제공사업신고번호: J1516020230005{' '}
        </div>
      </div>
    </>
  );
}
