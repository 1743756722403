import { Controller, useForm } from 'react-hook-form';
import Input from '../../custom/Input';
import Radio from '../../custom/Radio';

import PhoneNumberForm from './PhoneNumberForm'
;
import AddressForm from './AddressForm';
import EmailForm from './EmailForm';
import BirthYearForm from './BirthYearForm';
import GenderForm from './GenderForm';
import UsernameForm from './UsernameForm';
export default function BasicInfoForm ({currentUser, control, errors, data}) {
    return (
        <div className="flex mt-[40px] flex-col gap-[20px] items-start self-stretch m-auto text-[18px] tablet:text-[16px]">
            <div className="text-[20px] font-[600]">기본정보</div>
            <UsernameForm
              control={control}
              currentUser={currentUser}
            />
            <div className="flex items-center gap-[100px] self-stretch
                            tablet:flex-col tablet:items-stretch tablet:gap-[20px] ">
              <GenderForm
                control={control}
                currentUser={currentUser}
              />
              <BirthYearForm
                control={control}
                currentUser={currentUser}
              />
            </div>

            <PhoneNumberForm
              control={control}
              errors={errors}
              data={data?.phone}
            />
            <EmailForm
              control={control}
              errors={errors}
              data={data?.email}
            />
            <AddressForm
              control={control}
              errors={errors}
              data={{address:data?.address, addressDetail: data?.addressDetail}}
            />
          </div>
    );
}

