import { useLocation, useNavigate } from 'react-router-dom';
import HeaderWrapper, { TitleHeader } from '../../components/Header';
import AgreementItem from '../../components/sigup/AgreementItem';
import Button from '../../custom/Button';
import Checkbox from '../../custom/Checkbox';
import { useEffect, useMemo, useState } from 'react';
import { getAllAgreements } from '../../api';

export default function Agreement() {
  const [agreements, setAgreements] = useState([]);

  useEffect(() => {
    getAllAgreements()
      .then((res) => {
        // add agreed field and onAgreeAgreement function
        const agreementsWithAgreed = res.data.contents.map((content) => {
          return {
            ...content,
            agreed: false,
            onAgreeAgreement: (e, agreements) => {
              e.stopPropagation();

              const newAgreements = agreements.map((agreement) => {
                if (agreement.id === content.id) {
                  agreement.agreed = !agreement.agreed;
                }

                return agreement;
              });

              setAgreements(newAgreements);
            },
          };
        });

        setAgreements(agreementsWithAgreed);
      })
      .catch((e) => console.log(e));
  }, []);

  const onAgreeAll = (e) => {
    if (e.target.checked) {
      const newAgreements = agreements.map((elem) => {
        elem.agreed = true;
        return elem;
      });

      setAgreements(newAgreements);
    }
  };

  return (
    <>
      <HeaderWrapper
        element={<TitleHeader title="약관 동의 및 가입" backUrl="/" />}
      />
      <div className="mt-[60px] flex flex-col justify-center items-center">
        {agreements.length > 0 ? (
          <div className="flex flex-col gap-[40px] items-start w-[475px] tablet:w-[100%] tablet:p-[10px]">
            <div className="text-[22px] font-[600] leading-[30px]">
              서비스 이용을 위해서는 <br />
              약관 동의가 필요해요
            </div>
            <div>
              <div
                className={`flex w-[475px] tablet:w-[100%] h-[60px] items-center justify-center gap-[15px] py-[20px] px-[15px] rounded-[8px] border-[0.5px] border-solid border-[#D3D3DF] mb-[5px]`}
              >
                <Checkbox onClick={onAgreeAll} />
                <div className="text-[18px] flex-[1_0_0]">전체 동의</div>
              </div>
              {agreements.map((agreement) => (
                <AgreementItem
                  key={agreement.id}
                  title={`${agreement.title}${
                    agreement.required ? ' (필수)' : ' (선택)'
                  }`}
                  contentUrl={agreement.contentUrl}
                  checked={agreement.agreed}
                  onCheckClick={(e) =>
                    agreement.onAgreeAgreement(e, agreements)
                  }
                />
              ))}
            </div>
            <AgreeButton agreements={agreements} />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

function checkIfAllRequiredIsAgreed(agreements) {
  return agreements.every((agreement) => {
    return !agreement.required || agreement.agreed;
  });
}

function AgreeButton({ agreements }) {
  const navigate = useNavigate();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const isOAuthSignup = searchParams.get('isOAuthSignup') || false;
  const email = searchParams.get('email') || null;

  const requiredIsAllAgreed = useMemo(
    () => checkIfAllRequiredIsAgreed(agreements),
    [agreements],
  );

  const onAgree = () => {
    if (isOAuthSignup) {
      navigate('/signup/infoinput', {
        state: {
          isOAuthSignup,
          email,
          agreedAgreementIds: agreements.map((agreement) => {
            if (agreement.agreed) {
              return agreement.id;
            }
          }).filter(agreementId => !!agreementId),
        },
      });
    } else {
      navigate('/signup/mobileNumberVerification');
    }
  };

  return (
    <Button
      className={`${
        requiredIsAllAgreed ? 'bg-[#440FDC]' : 'bg-gray-300'
      } pt-[19px] pb-[20px] w-[100%] text-white text-[20px] font-[600]`}
      disabled={!requiredIsAllAgreed}
      onClick={requiredIsAllAgreed ? onAgree : null}
    >
      동의하기
    </Button>
  );
}
