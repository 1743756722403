import Radio from '../../custom/Radio';
import { Controller} from 'react-hook-form';

export default function ComputerAbilityForm({control,errors,data}) {
    const readOnly = (control == null) || data!=null;
    return (
        <div className="flex mt-[40px] flex-col gap-[20px] items-start, self-stretch, m-auto">
            <div className='flex tablet:flex-col tablet:items-start items-center'>
              <div className="text-[20px] font-[600]">컴퓨터 활용능력</div>
              <div className="ml-[10px] tablet:ml-0 font-[300]">업무를 하기 위한 간단한 자가진단 질문이에요. 부담없이 답해주세요.</div>
            </div>
            <div> 나는 인터넷으로 자료 열람 및 제출, 세금/공과금 조회 및 납부 등 행정 일을 처리할 수 있다. <br/> (1. 혼자서는 시도하기 어려움 ~ 5. 큰 어려움 없이 할 수 있음)</div>
            <div className="flex tablet:flex-col gap-[30px] tablet:gap-[20px]">
              <div className="flex gap-[20px] items-center">
                {!readOnly?
                <Controller
                  name="computerAbility"
                  control={control}
                  render={({ field }) => (
                    <Radio
                      {...field}
                      name="computerAbility"
                      id="computer_1"
                      value={1}
                    />
                  )}
                />
                :
                <Radio
                    checked={data === 1} disabled
                    name="computerAbility"
                    id="computer_1"
                    value={1}
                />
                }
                <label className="text-[18px]" for="computer_1">
                  1
                </label>
              </div>
              <div className="flex gap-[20px] items-center">
                {!readOnly?
                <Controller
                  name="computerAbility"
                  control={control}
                  render={({ field }) => (
                    <Radio
                      {...field}
                      name="computerAbility"
                      id="computer_2"
                      value={2}
                    />
                  )}
                />
                :
                <Radio
                  checked={data === 2} disabled
                  name="computerAbility"
                  id="computer_2"
                  value={2}
                />
                }
                <label className="text-[18px]" htmlFor="computer_2">
                  2
                </label>
              </div>
              <div className="flex gap-[20px] items-center">
                {!readOnly?
                <Controller
                  name="computerAbility"
                  control={control}
                  render={({ field }) => (
                    <Radio
                      {...field}
                      name="computerAbility"
                      id="computer_3"
                      value={3}
                    />
                  )}
                />
                :
                <Radio
                  checked={data === 3} disabled
                  name="computerAbility"
                  id="computer_3"
                  value={3}
                />
                }
                <label className="text-[18px]" htmlFor="computer_3">
                  3
                </label>
              </div>
              <div className="flex gap-[20px] items-center">
                {!readOnly?
                <Controller
                  name="computerAbility"
                  control={control}
                  render={({ field }) => (
                    <Radio
                      {...field}
                      name="computerAbility"
                      id="computer_4"
                      value={4}
                    />
                  )}
                />
                :
                <Radio
                  checked={data === 4} disabled
                  name="computerAbility"
                  id="computer_4"
                  value={4}
                />
                }
                <label className="text-[18px]" htmlFor="computer_4">
                  4
                </label>
              </div>
              <div className="flex gap-[20px] items-center">
                {!readOnly?
                <Controller
                  name="computerAbility"
                  control={control}
                  render={({ field }) => (
                    <Radio
                      {...field}
                      name="computerAbility"
                      id="computer_5"
                      value={5}
                    />
                  )}
                />
                :
                <Radio
                  checked={data === 5} disabled
                  name="computerAbility"
                  id="computer_5"
                  value={5}
                />
                }
                <label className="text-[18px]" htmlFor="computer_5">
                  5
                </label>
              </div>
            </div>
            {errors?.computerAbility && (
              <div className="error">{errors?.computerAbility.message}</div>
            )}
        </div>
    );
}