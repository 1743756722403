import axios from 'axios';
import { toast } from 'react-toastify';

export const KAKAO_LOGIN_URL = '/oauth2/authorization/kakao';

const httpClient = axios.create({
  baseURL:
    process.env.REACT_APP_PROFILE === 'PROD'
      ? process.env.REACT_APP_API_URL
      : null,
  timeout: 3000,
  withCredentials: true,
});

httpClient.interceptors.response.use(
  function (response) {
    // console.log(response);
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      alert('해당 서비스는 로그인 후 이용 가능합니다.');
      window.location.href = '/';
      return Promise.reject(error);
    } else {
      toast('서비스 네트워크 에러입니다. 관리자에게 문의하세요.');

      return Promise.reject(error);
    }
  },
);

export const postKakaoLogin = () => httpClient.post(KAKAO_LOGIN_URL);

export const logout = () => httpClient.post('/v1/auth/logout');

export const kakaoSignup = (data) =>
  httpClient.post('/v1/oauth2/kakao/signup', data);

export const getAuthUser = () => httpClient.get('/v1/auth/session');

export const getCurrentUserInfo = () => httpClient.get('/v1/auth/session/user');

export const getAllAgreements = () => httpClient.get('/v1/agreements');

export const getAllPrograms = (page = 0, size = 5) =>
  httpClient.get('/v1/higherre-programs', {
    params: {
      page,
      size,
      sort: 'createdDateTime,asc'
    },
  });

export const getProgram = (id) => httpClient.get(`/v1/higherre-programs/${id}`);

export const getResumeData = (id) => httpClient.get(`/v1/resumes/${id}`);

export const postResumeAndApply = (programId, data) =>
  httpClient.post(`/v1/resumes/application/${programId}`, data);

export const getFormSubcategories = () =>
  httpClient('/v1/resumes/subfield-types');
export const getResumeAgreements = () => httpClient('/v1/resumes/agreements');

export const getCurrentUserApplications = (page = 0, size = 5) =>
  httpClient.get('/v1/resumes/current-user/applications', {
    params: {
      page,
      size,
    },
  });

export const updateUserInfo = (userId, data) =>
  httpClient.patch(`/v1/users/${userId}`, data);

export const deleteResumeWithApplication = (resumeId) =>
  httpClient.delete(`/v1/resumes/${resumeId}`);

export const editResumeData = (resumeId, data) =>
  httpClient.put(`/v1/resumes/${resumeId}`, data);