import { useState,useRef, useCallback, useEffect } from 'react';
import { set } from 'react-hook-form';

export default function useEditFormItems(initialValue,itemsError,setItemsError) {
    // education
    const [educations, setEducations] = useState([]);
    const onEducationChange = useCallback((e, id) => {
        const { name, value } = e.target;
        setEducations(currentEducations =>
            currentEducations.map(item => 
                item.id === id ? { ...item, content: { ...item.content, [name]: value }} : item
            )
        );
    }, []);
    
    const nextEducationId = useRef(2);
    const onEducationCreate = useCallback(() => {
        if (educations.length > 10){
            console.log("cannot add more item")
            alert("10개 이상 입력할 수 없습니다.")
            return
        }
        for (let i = 0 ; i < initialValue.educations.length ; i++){
            const {type,status,name,major} =initialValue.educations[i].content
            if ( !type || !name || !status ) {
                console.log("add item after filling every input")
                alert("모든 항목에 학력과 학교명을 입력한 후 항목을 추가해주세요.")
                return
            }
        }
        setEducations([
            ...initialValue.educations,
            {
                id : nextEducationId.current,
                content : {
                    degree: initialValue.educations[0].content.type, status:initialValue.educations[0].content.status, name:"", major:"",isFinal:false
                }
            }
        ])
        nextEducationId.current += 1
    },[initialValue.educations]);

    const onEducationDelete = useCallback((id) => {
        if (educations.length <=0) {
            console.log("one item needed at least")
            return
        }
        setEducations(initialValue.educations.filter(item=>item.id!==id))
        
    },[initialValue.educations]);

    


    // workExperience //
    const [workExperiences,setWorkExperiences] = useState(initialValue.workExperiences)
    const onWorkExperienceChange = useCallback((e, id) => {
        const {name, value} = e.target;
        setWorkExperiences(
            workExperiences.map(item =>
                item.id === id ? {...item, content : {...item.content, [name]:value }} : item)
        )
    },[workExperiences]);
    const nextWorkExperienceId = useRef(2);
    const onWorkExperienceCreate = useCallback(() => {
        if (workExperiences.length > 10){
            console.log("cannot add more item")
            alert("10개 이상 입력할 수 없습니다.")
            return
        }

        for (let i = 0 ; i < workExperiences.length ; i++){
            const {type,name,startYear, startMonth, endYear, endMonth} =workExperiences[i].content
            if ( !type || !name || !startYear || !startMonth || !endYear || !endMonth ) {
                console.log("add item after filling every input")
                alert("모든 항목에 내용을 입력한 후 항목을 추가해주세요.")
                return
            }
        }
        setWorkExperiences([
            ...workExperiences,
            {
                id : nextWorkExperienceId.current,
                content : {
                    type: "", name:"",
                    startYear: undefined,
                    startMonth: undefined,
                    endYear : undefined,
                    endMonth: undefined,
                }
            }
        ])
        nextWorkExperienceId.current += 1
    },[workExperiences]);
    const onWorkExperienceDelete = useCallback((id,confirmMessage="정말 삭제하시겠습니까?") => {
        if (workExperiences.length <=0) {
            console.log("one item needed at least")
            return
        }

        if (window.confirm(confirmMessage) === true){    //확인
            setWorkExperiences(workExperiences.filter(item=>item.id!==id))
        } else{   //취소
            return false;
        }
    },[workExperiences]);

    // Certificate
    const [certificates,setCertificates] = useState(initialValue.certificates)

    const onCertificateChange = useCallback((e, id) => {
        const {name, value} = e.target;
        setCertificates(
            certificates.map(item =>
                item.id === id ? {...item, content : {...item.content, [name]:value }} : item)
        )
    },[certificates]);
    const nextCertificateId = useRef(2);
    const onCertificateCreate = useCallback(() => {
        if (certificates.length >= 10){
            alert("10개 이상 입력할 수 없습니다.")
            return
        }
        for (let i = 0 ; i < certificates.length ; i++){
            const {type,name,acquisitionYear,acquisitionMonth} =certificates[i].content
            if ( !type || !name || !acquisitionYear || !acquisitionMonth ) {
                alert("모든 항목에 내용을 입력한 후 항목을 추가해주세요.")
                return
            }
        }
        
        setCertificates([
            ...certificates,
            {
                id : nextCertificateId.current,
                content : {
                    type: "", name:"", 
                    acquisitionYear : undefined,
                    acquisitionMonth : undefined
                }
            }
        ])
        nextCertificateId.current += 1
    },[certificates]);

    const onCertificateDelete = useCallback((id,confirmMessage="정말 삭제하시겠습니까?") => {
        if (certificates.length <=0) {
            console.log("one item needed at least")
            return
        }
        if (window.confirm(confirmMessage) === true){    //확인
            setCertificates(certificates.filter(item=>item.id!==id))
        } else{   //취소
            return false;
        }
    },[certificates])

    useEffect(()=>{
        if(initialValue.educations[0].content.status !== ""){
            setEducations(initialValue.educations)
        }
    },[initialValue])

    useEffect(()=>{
        if(initialValue.workExperiences[0].content.type !== ""){
            setWorkExperiences(initialValue.workExperiences)
        }
    },[initialValue])

    useEffect(()=>{
        console.log(initialValue.certificates[0]);
        if(initialValue.certificates.length > 0 && initialValue.certificates[0].content.type !== ""){
            console.log(initialValue.certificates);
            setCertificates(initialValue.certificates)
        } else {
            setCertificates([{
                id: 1,
                content: {
                    type: "",
                    name: "",
                    acquisitionTime: ""
                }
            }])
            console.log("=====certificates",certificates);
        }
    },[initialValue])
    
    return {educations, onEducationChange, onEducationCreate, onEducationDelete,
            certificates, onCertificateChange, onCertificateCreate, onCertificateDelete,
            workExperiences, onWorkExperienceChange, onWorkExperienceCreate, onWorkExperienceDelete
    }
}