import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getProgram } from '../../api';
import Button from '../../custom/Button';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from '../../reducers/auth/authReducer';
import { open } from '../../reducers/loginModal/loginModalReducer';

export default function DetailedProgramPage() {
  const params = useParams();
  const [program, setProgram] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getProgram(params.id)
      .then((res) => {
        setProgram(res?.data);
        if (res?.data) {
          setIsLoaded(true);
        }
      })
      .catch();
  }, []);

  const navigate = useNavigate();

  const user = useSelector(selectAuth);
  const dispatch = useDispatch();

  const onApply = () => {
    if (program?.id === 2) {
      window.location.href =
        'https://50plus.or.kr/in_appView.do?ANN_NO=286&setPageIndex=1';
    }
    else if (program?.id === 3) {
      window.location.href =
        'https://50plus.or.kr/in_appView.do?ANN_NO=304&setPageIndex=1';
    }
    else if (program?.id === 4) {
      window.location.href =
        'https://50plus.or.kr/in_appView.do?ANN_NO=377&setPageIndex=1';
    }
    else if (program?.id === 5) {
      window.location.href =
        'https://forms.gle/8qRAnSBisnXYMPiy8';
    }
    else if (program?.id === 6) {
      window.location.href =
        'https://50plus.or.kr/in_appView.do?ANN_NO=579&setPageIndex=1';
    }
    else if (!program?.inProgress) {
      alert('프로그램 신청 기간이 지났습니다.');
    } else if (!user) {
      alert('로그인 후 이용 가능합니다.');
      dispatch(open());
    } else {
      navigate(`/programs/${params.id}/resume-form`);
    }
  };

  return (
    <>
      {isLoaded && (
        <>
          <div className="flex tablet:flex-col py-[20px] px-0 justify-between items-center content-center gap-y-[30px] flex-wrap bg-[#FFF]">
            <div className="flex items-center gap-[20px] shrink-0">
              <img
                src="/images/icon_arrow.svg"
                className="cursor-pointer tablet:hidden"
                onClick={() => navigate(-1)}
              />
              <div className="flex flex-col justify-center items-start gap-[10px] flex-[1_0_0]">
                <div className="flex tablet:flex-col items-center gap-[15px] self-stretch text-[24px] font-[700]">
                  <div className='tablet:text-center'>{program?.name}</div>
                  <div className="flex h-[30px] p-[10px] justify-center items-center gap-[10px] rounded-[5px] bg-[#FFF2F2] text-[15px] font-[600] leading-[28px] text-[#E40D41]">
                    {getDDayCountText(program?.dDayCount)}
                  </div>
                </div>
                <div className="tablet:w-[100%] tablet:text-center">
                  {program?.recruitStartDateTime
                    ? moment(program?.recruitStartDateTime).format(
                        'YYYY.MM.DD',
                      ) +
                      '- ' +
                      moment(program?.recruitEndDateTime).format('YYYY.MM.DD')
                    : '상시모집'}
                </div>
                <div className="text-[17px] text-[#535863] tablet:w-[100%] tablet:text-center">
                  {program?.companyName ? `${program?.companyName}` : ''}
                </div>
              </div>
            </div>
            <div>
              <Button
                className="text-[#FFF] bg-[#440FDC] text-[20px] font-[600]"
                onClick={onApply}
              >
                신청하기
              </Button>
            </div>
          </div>
          {program?.imgUrls.map((imgUrl, i) => {
            return <img key={i} src={imgUrl} className="w-[1000px]" />;
          })}
        </>
      )}
    </>
  );
}

function getDDayCountText(dDayCount) {
  let text = 'D';
  if (dDayCount === null) {
    text = '상시모집';
  } else if (dDayCount < 0) {
    text = `D${dDayCount}`;
  } else if (dDayCount > 0) {
    text = `D+${dDayCount}`;
  } else if (dDayCount === 0) {
    text = '오늘 마감';
  }
  return text;
}
