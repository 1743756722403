import { useEffect, useState } from "react";
import { getFormSubcategories } from "../../api";

export default function useFormSubcategories() {
  const [formSubcategories, setFormSubcategories] = useState({
    educationTypes:[],
    workExperienceTypes:[],
    certificateTypes:[]
  });

  useEffect(() => {
    getFormSubcategories()
      .then(res => {
        setFormSubcategories(res.data);
      })
      .catch(e => console.log(e))
  }, [])

  return formSubcategories;
}