import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCurrentUserInfo } from '../api';
import { set } from '../reducers/auth/authReducer';

function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getCurrentUserInfo().then((res) => {
      dispatch(set(res.data));
      setIsLoaded(true);
    }).catch(e => {
      console.log(e);
      setIsLoaded(true);
    });
  }, []);

  return <>{isLoaded ? children : null}</>;
}

export default AuthProvider;
