import { useEffect, useRef, useState } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import ProgramCardView from '../components/program/ProgramCardView';

import { Carousel } from 'antd';
import { child, get, getDatabase, ref } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import { getAllPrograms } from '../api';
import firebase from '../firebase';

export default function Home() {
  const navigate = useNavigate();
  const [jobInformations, setJobInformations] = useState([]);

  useEffect(() => {
    const dbRef = ref(getDatabase(firebase));
    get(child(dbRef, '/job-informations')).then((snapshot) => {
      if (snapshot.exists()) {
        setJobInformations(snapshot.val());
      }
    });
  }, []);

  return (
    <div className="flex flex-col gap-[30px]">
      <div style={{ marginTop: '0px' }}></div>
      <div className="flex flex-col gap-[30px]">
        <div className="text-[#111] text-[22px] font-[600] leading-[30px]">
          <div className="flex flex-row">
            <div
              className="flex cursor-pointer"
              onClick={() => navigate('/programs')}
            >
              <span className="inline text-[30px]">프로그램</span>{' '}
              <img src="images/left_arrow.svg" className="rotate-180" />
            </div>
          </div>
        </div>
        <ProgramSwiper />
        <HomeBanner />
      </div>
      <div className="flex flex-col gap-[20px]">
        <div className="text-[#111] text-[22px] font-[600] leading-[30px]">
          <div className="flex flex-row">
            <span className="inline text-[30px]">서비스 소개</span>{' '}
            <img src="images/left_arrow.svg" className="rotate-180" />
          </div>
        </div>
        <div className="flex flex-wrap gap-[15px]">
          {[...jobInformations].reverse().map((jobInformation, i) => (
            <ProgramCardView
              program={{
                id: i,
                thumbnailUrl: jobInformation?.imgUrl,
                name: jobInformation?.title,
                extLink: jobInformation?.extLink,
              }}
            />
            // <div key={i} className="w-[320px]">
            //   <a href={jobInformation?.extLink} target="_blank">
            //     <img src={jobInformation?.imgUrl} />
            //   </a>
            // </div>
          ))}
        </div>
      </div>
      {/* <div className="flex flex-col gap-[20px]">
        <div className="text-[#111] text-[22px] font-[600] leading-[30px]">
          <div className="flex flex-row">
            <span className="inline text-[30px]">채용정보</span>{' '}
            <img src="images/left_arrow.svg" className="rotate-180" />
          </div>
        </div>
        <div>등록된 채용정보가 없습니다.</div>
      </div> */}
    </div>
  );
}

function HomeBanner() {
  return (
    <>
      <div className="tablet:hidden rounded-[8px] mt-[0px] overflow-hidden h-[160px]">
        {/* 모집중 아닐 때 홈배너  */}
        {/* <Carousel autoplay autoplaySpeed={8000}>
          <a href="https://higher-re.imweb.me">
            <img src="images/home-banners/pc/001.jpg" />
          </a>
          <a href="/programs/1">
            <img src="images/home-banners/pc/002-1.jpeg" />
          </a>
          <a href="/programs/1">
            <img src="images/home-banners/pc/002.jpg" />
          </a>
          <a href="/programs/1">
            <img src="images/home-banners/pc/003.jpg" />
          </a>
          <img src="images/home-banners/pc/004.jpg" />
        </Carousel> */}
        <Carousel autoplay autoplaySpeed={8000}>
          <a href="https://50plus.or.kr/in_appView.do?ANN_NO=304&setPageIndex=1">
            <img src="images/home-banners/pc/001-recruiting.jpg" />
          </a>
          <a href="/programs/1">
            <img src="images/home-banners/pc/002-recruiting.jpg" />
          </a>
          <a href="/programs/1">
            <img src="images/home-banners/pc/003-recruiting.jpg" />
          </a>
        </Carousel>
      </div>
      <div className="hidden tablet:block mt-[40px] overflow-hidden w-[100%]">
        {/* <Carousel autoplay autoplaySpeed={8000}>
          <a href="https://higher-re.imweb.me">
            <img src="images/home-banners/mobile/001.jpg" />
          </a>
          <a href="/programs/1">
            <img src="images/home-banners/mobile/002-1.jpeg" />
          </a>
          <a href="/programs/1">
            <img src="images/home-banners/mobile/002.jpg" />
          </a>
          <a href="/programs/1">
            <img src="images/home-banners/mobile/003.jpg" />
          </a>
          <img src="images/home-banners/mobile/004.jpg" />
        </Carousel> */}
        <Carousel autoplay autoplaySpeed={8000}>
          <a href="https://50plus.or.kr/in_appView.do?ANN_NO=304&setPageIndex=1">
            <img src="images/home-banners/mobile/001-recruiting.jpg" />
          </a>
          <a href="/programs/1">
            <img src="images/home-banners/mobile/002-recruiting.jpg" />
          </a>
          <a href="/programs/1">
            <img src="images/home-banners/mobile/003-recruiting.jpg" />
          </a>
        </Carousel>
      </div>
    </>
  );
}

function ProgramSwiper() {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    getAllPrograms(0, 10)
      .then((res) => {
        setPrograms(res.data.content);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div>
      {programs?.length ? (
        <>
          <Swiper
            setWrapperSize={true}
            modules={[Navigation]}
            breakpoints={{
              640: {
                slidesPerView: 3,
              },
              405: {
                slidesPerView: 1.2
              }
            }}
            slidesPerView={1}
            autoplay={false}
            navigation
          >
            {programs.map((program) => {
              return (
                <SwiperSlide key={program.id}>
                  <ProgramCardView program={program} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </>
      ) : (
        <>등록된 프로그램이 없습니다.</>
      )}
    </div>
  );
}
