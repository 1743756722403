import { useEffect, useState } from "react";
import { getCurrentUserInfo } from "../api";

export default function useCurrentUser() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    getCurrentUserInfo()
      .then(res => {
        setCurrentUser(res.data);
      })
      .catch(e => console.log(e))
  }, [])

  return currentUser;
}