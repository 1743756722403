import { Link, useNavigate } from 'react-router-dom';
import { getAuthUser } from '../../api';

export default function ProgramCardView({ program }) {
  const navigate = useNavigate();

  const onNavigate = () => {
    if(program?.extLink) {
      window.open(program?.extLink, '_blank');
    } else {
      if(!program?.inProgress) {
        alert('프로그램 신청 기간이 지났습니다.');
      } else {
        checkAuthentication(() => navigate(`/programs/${program?.id}`));
      }
    }
  };

  return (
    <div
      className="hover:bg-[#EEEEEE] rounded-[8px] w-[320px] flex flex-col cursor-pointer relative border-[1.5px]"
      onClick={onNavigate}
    >
      {program?.dDayCount !== undefined && <div className={`absolute left-[10px] top-[10px] flex h-[30px] p-[10px] justify-center items-center gap-[10px] rounded-[5px]  text-[15px] font-[600] leading-[28px] ${program?.inProgress ? "text-[#E40D41] bg-[#FFF2F2]" : "text-[#888888] bg-[#EEEEEE]"}`}>
        {program?.inProgress ? getDDayCountText(program?.dDayCount) : '모집마감'}
      </div>}
      <img
        src={program?.thumbnailUrl}
        alt="사진이 없습니다"
        className="h-[180px] object-left-top object-cover self-stretch rounded-t-[8px] bg-[#4A4A4A] bg-opacity-50"
      />
      <div className="h-[116px] flex flex-col flex-grow p-[20px] items-start gap-[10px] self-stretch rounded-b-[8px] border-[#D3D3DF] text-[20px] font-[600]">
        <div>{program?.name}</div>
        <div className="mt-auto text-[#888888] text-[15px] font-normal">
          {program?.description}
        </div>
      </div>
    </div>
  );
}

function checkAuthentication(thenFunc) {
  getAuthUser()
    .then((res) => thenFunc())
    .catch((e) => console.log(e));
}

function getDDayCountText(dDayCount) {
  let text = "D";

  if (dDayCount === null) {
    text = '상시모집';
  } else if (dDayCount < 0) {
    text = `D${dDayCount}`;
  } else if (dDayCount > 0) {
    text = `D+${dDayCount}`;
  } else if (dDayCount === 0) {
    text = '오늘 마감';
  }
  return text;
}
