export default function Checkbox(props) {
  const new_props = {
    ...props,
    className: `${props.className} w-[30px] h-[30px] flex-shrink-0 border-[1px] rounded-[3px] border-[#B0B0BD] accent-[#440FDC] text-[18px] font-[600]`,
  };

  return (
    <input
      type="checkbox"
      {...new_props}
    />
  );
}
