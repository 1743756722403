import {twMerge} from 'tailwind-merge'
export default function Button(props) {

  const className = twMerge('pt-[14px] px-[15px] pb-[15px] border-[#D3D3DF] border-solid border-[1px] rounded-lg text-[18px] text-[#111111]',props.className)


  return(
    <button {...props} className={className}>
      {props.children}
    </button>
  )
}