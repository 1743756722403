import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { postResumeAndApply, editResumeData } from '../../api';

import HeaderWrapper, { TitleHeader } from '../../components/Header';
import TitleForm from '../../components/resumes/TitleForm';
import MotivationForm from '../../components/resumes/MotivationForm';
import BasicInfoForm from '../../components/resumes/BasicInfoForm';
import EditResumeEducationForm from '../../components/resumes/EditResumeEducationForm';
import EditResumeExperienceForm from '../../components/resumes/EditResumeExperienceForm';
import EditResumeCertificateForm from '../../components/resumes/EditResumeCertificateForm';
import EditComputerAbilityForm from '../../components/resumes/EditComputerAbilityForm';
import EditDesiredWorkTypeForm from '../../components/resumes/EditDesiredWorkTypeForm';
import EditDesiredWorkRegionForm from '../../components/resumes/EditDesiredWorkRegionForm';
import AgreementForm from '../../components/resumes/AgreementForm';
import SubmitEditButton from '../../components/resumes/SubmitEditButton';

import useEditFormItems from '../../hooks/resumes/useEditFormItems';
import useItemValidation from '../../hooks/resumes/useItemValidation';
import useFormAgreements from '../../hooks/resumes/useFormAgreements';
import useFormSubcategories from '../../hooks/resumes/useFormSubcatgories';
import useCurrentUser from '../../hooks/useCurrentUser';

import useResumeData from '../../hooks/resumes/useResumeData';

export default function ResumeForm({readOnly=false}) {
  const navigate = useNavigate();  

  // resume 정보 가져옴
  const resumeId = useResumeId();

  const resumeData = useResumeData(resumeId);

  // educations, workExperiences, certificates 조기선언
  const [initialDefaultItems, setInitialDefaultItems] = useState({
    educations: [
      {
        id: 1,
        content: {
          degree: resumeData['educations'][0]['degree'],
          status: resumeData['educations'][0]['status'],
          name: '',
          major: resumeData['educations'][0]['major'],
          isFinal: true,
        },
      },
    ],
    workExperiences: [
      {
        id: 1,
        content: {
          type: '',
          name: '',
          startYear: undefined,
          startMonth: undefined,
          endYear: undefined,
          endMonth: undefined,
        },
      },
    ],
    certificates: [
      {
        id: 1,
        content: {
          acquisitionDateTime: '',
          name: '',
          type: '',
        },
      },
    ],
  });

  // resumeData가 변경될 때마다 initialDefaultItems를 업데이트합니다.
  useEffect(() => {
    const updatedInitialDefaultItems = {
      educations: resumeData.educations.map((education, index) => ({
        id: index + 1,
        content: {
          degree: education.degree || "",
          status: education.status || "",
          name: education.name || "",
          major: education.major || "",
          isFinal: education.isFinal || true,
        },
      })),
      workExperiences: resumeData.workExperiences.map((experience, index) => ({
        id: index + 1,
        content: {
          type: experience.occupationType || "",
          name: experience.occupation || "",
          startYear: experience.startDateTime ? new Date(experience.startDateTime).getFullYear() : undefined,
          startMonth: experience.startDateTime ? new Date(experience.startDateTime).getMonth() + 1 : undefined,
          endYear: experience.endDateTime ? new Date(experience.endDateTime).getFullYear() : undefined,
          endMonth: experience.endDateTime ? new Date(experience.endDateTime).getMonth() + 1 : undefined,
        },
      })),
      certificates: resumeData.certificates.map((certificate, index) => ({
        id: index + 1,
        content: {
          type: certificate.type || "",
          name: certificate.name || "",
          acquisitionDateTime: certificate.acquisitionDateTime || undefined
        },
      })),
    };
    // console.log("updatedInitialDefaultItems", updatedInitialDefaultItems);
    setInitialDefaultItems(updatedInitialDefaultItems);
  }, [resumeData]);


    const {
      educations, onEducationChange, onEducationCreate, onEducationDelete,
      workExperiences, onWorkExperienceChange, onWorkExperienceCreate, onWorkExperienceDelete,
      certificates, onCertificateCreate, onCertificateChange, onCertificateDelete,
    } = useEditFormItems(initialDefaultItems);
    // const educations = resumeData.educations;
    // const workExperiences = resumeData.workExperiences;
    // const certificates = resumeData.certificates;


  const {
    itemsError, validateEducations, validateWorkExperiences, validateCertificates
  } = useItemValidation(educations,workExperiences,certificates);

  const {
    agreements,
    agreedAgreementIds, setAgreedAgreementIds,
    agreementErrors, validateAgreements
  } = useFormAgreements();

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required('이력서 제목은 필수로 입력해 주셔야 합니다.')
      .max(100, '제목은 100자를 넘을 수 없습니다.'),
    motivation: Yup.string()
      .required('지원 동기는 필수로 입력해 주셔야 합니다.')
      .max(500, '지원동기는 500자를 넘을 수 없습니다.'),
    phoneNumber: Yup.string()
      .required('전화번호는 필수로 입력해 주셔야 합니다.')
      .max(32,'입력이 너무 깁니다. 전화번호를 확인해주세요.'),
    email: Yup.string() 
      .required('이메일은 필수로 입력해 주셔야 합니다.')
      .email('이메일 형식이 올바르지 않습니다')
      .max(100,'입력이 100자를 넘을 수 없습니다.'),
    addressHead: Yup.string()
      .required('주소는 필수로 입력해 주셔야 합니다.')
      .max(100,'입력이 100자를 넘을 수 없습니다.'),
    addressDetail: Yup.string()
      .required('상세주소는 필수로 입력해 주셔야 합니다.')
      .max(100,'입력이 100자를 넘을 수 없습니다.'),
    computerAbility: Yup.number()
      .required('컴퓨터 활용능력은 필수로 입력해주셔야 합니다.'),
    workType : Yup.string()
      .required('희망 근무형태는 필수로 입력해 주셔야 합니다.')
      .max(100,'입력이 100자를 넘을 수 없습니다.'),
    workRegion : Yup.string()
      .required('희망 근무 지역은 (시,군 또는 구까지) 필수로 입력해 주셔야 합니다.')
      .max(100,'입력이 100자를 넘을 수 없습니다.'),
    workRegionCity : Yup.string()
      .required('희망 근무 지역은 (시,군 또는 구까지) 필수로 입력해 주셔야 합니다.')
      .max(100,'입력이 100자를 넘을 수 없습니다.'),
  });


  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm(formOptions);



    // title 필드에 resumeData.title 값을 설정합니다.
    useEffect(() => {
      setValue('title', resumeData.title || ''); 
      setValue('motivation', resumeData.motivation || ''); 
      setValue('phoneNumber', resumeData.phone || ''); 
      setValue('email', resumeData.email || '');
      setValue('addressHead', resumeData.address || ''); 
      setValue('addressDetail', resumeData.addressDetail || '');
      setValue('workType', resumeData.workType || '');
      setValue('workRegion', resumeData.workRegion || '');
      setValue('workRegionCity', resumeData.workRegionCity || '');
      setValue('computerAbility', resumeData.computerAbility || 1);
    }, [setValue, resumeData.title, resumeData.motivation, resumeData.phoneNumber, resumeData.email, resumeData.address, resumeData.addressDetail, resumeData.workType, resumeData.workRegion, resumeData.workRegionCity, resumeData.computerAbility]);
  
  const { programId } = useParams();

  const onResumeSubimit = useCallback(
    (data) => {
      // const educationsOk = validateEducations();
      // const workExperiencesOk = validateWorkExperiences();
      // const certificatesOk = validateCertificates();
      const educationsOk = 1
      const workExperiencesOk = 1
      const certificatesOk = 1
      if (
        ! ( educationsOk && workExperiencesOk && certificatesOk) // make sure that all of the items validated
      ) {
        alert('이력서 형식이 올바르지 않습니다. 내용을 다시 한 번 확인해 주세요.');
        return;
      }
      data['workExperiences'] = workExperiences;
      data['certificates'] = certificates;
      data['educations'] = educations;
      
      const requestData= convertFormData(data)
      // console.log("data['workExperiences'] : ", data['workExperiences'] );
      // validate agreements and add it to the requestData
      if (!validateAgreements(requestData)) {
        alert('동의 항목을 확인해주세요.');
        return;
      }

      requestData['agreedAgreementIds']=Array.from(agreedAgreementIds);
      // console.log("====requestData: ", requestData);
      editResumeData(resumeId, requestData)
        .then(() => {
          alert('이력서 수정이 완료되었어요! 검토 후 인재정보에 등록됩니다. 매칭 시 연락드리기 위해 카톡 채널 친구 추가를 꼭 해주세요.');
          navigate(-1);
        })
        .catch((error) =>
          alert(
            '오류가 발생했습니다. 잠시 후 다시 시도해주세요. 오류가 반복될 경우 관리자에게 문의해주세요.',
          ),
        );
    },
    [educations, workExperiences, certificates, agreedAgreementIds],
  );

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      alert(
        '이력서 형식이 올바르지 않습니다. 내용을 다시 한 번 확인해 주세요.',
      );
    }
  }, [errors]);

  const currentUser = useCurrentUser();
  // console.log("currentUser :", currentUser )
  const formSubcategories = useFormSubcategories();

  return (
    <div className="flex-col justify-center tablet:w-[100%] ">
        <HeaderWrapper element={<TitleHeader title="이력서 수정" />} />
        <form
          onSubmit={handleSubmit(onResumeSubimit)}
          className="w-[1000px] tablet:w-[100%] tablet:px-[30px] mb-[40px] m-auto"
        >
          <TitleForm
            control={control}
            errors={errors}
          />

          <MotivationForm
            control={control}
            watch={watch}
            errors={errors}
          />

          <BasicInfoForm
            currentUser={currentUser}
            control={control}
            errors={errors}
          />

          <EditResumeEducationForm
            items={educations}
            onCreate={onEducationCreate}
            onChange={onEducationChange}
            onDelete={onEducationDelete}
            categories={formSubcategories.educationTypes}
            itemsError={itemsError}
          />
          <EditResumeExperienceForm
            items={workExperiences}
            onCreate={onWorkExperienceCreate}
            onChange={onWorkExperienceChange}
            onDelete={onWorkExperienceDelete}
            categories={formSubcategories.workExperienceTypes}
            itemsError={itemsError}
          />
          <EditResumeCertificateForm
            items={certificates}
            onCreate={onCertificateCreate}
            onChange={onCertificateChange}
            onDelete={onCertificateDelete}
            categories={formSubcategories.certificateTypes}
            itemsError={itemsError}
          />
          <EditComputerAbilityForm
            control={control}
            errors={errors}
            data={resumeData.computerAbility}
          />
          <EditDesiredWorkTypeForm
            control={control}
            errors={errors}
            data={resumeData.workType}
          />
          <EditDesiredWorkRegionForm 
            control={control}
            errors={errors}
            data={{'region':resumeData.workRegion, 'city':resumeData.workRegionCity}}
          />

          <AgreementForm
            control={control}
            errors={errors}
            agreements={agreements}
            setAgreedAgreementIds={setAgreedAgreementIds}
            agreementErrors={agreementErrors}
          />

          { !readOnly && <SubmitEditButton/>}
            
          
        </form>
    </div>
  );
}

const convertFormData = (formData) => {
  return {
    title: formData['title'],
    motivation: formData['motivation'],
    phone: formData['phoneNumber'],
    email: formData['email'],
    address: formData['addressHead'],
    addressDetail: formData['addressDetail'],
    computerAbility: formData['computerAbility'],
    workType : formData['workType'],
    workRegion: formData['workRegion'],
    workRegionCity: formData['workRegionCity'],
    educations: formData['educations'].reduce(
      (result, { content: { degree, status, name, major, isFinal } }) => {
        if (degree && status) {
          result.push({
            degree: degree,
            status: status,
            school: name,
            major: major,
            isFinal: isFinal,
          });
        }
        return result;
      },
      [],
    ),
    workExperiences: formData['workExperiences'].reduce(
      (
        result,
        { content: { type, name, startYear, startMonth, endYear, endMonth } },
      ) => {
        if (type && name && startYear && startMonth && endYear && endMonth) {
          result.push({
            occupationType: type,
            occupation: name,
            startDateTime: convertYearMonth(startYear, startMonth),
            endDateTime: convertYearMonth(endYear, endMonth),
          });
        }
        return result;
      },
      [],
    ),
    certificates: formData['certificates'].reduce(
      (
        result,
        { content: { type, name, acquisitionYear, acquisitionMonth } },
      ) => {
        if (type && name && acquisitionYear && acquisitionMonth) {
          result.push({
            type: type,
            name: name,
            acquisitionDateTime: convertYearMonth(
              acquisitionYear,
              acquisitionMonth,
            ),
          });
        }
        return result;
      },
      [],
    ),
  };
};
// console.log("convertFormData :",requestData);

const convertYearMonth = (year, month) => {
  const date = new Date(year, month-1);
  return new Date(
    date.getTime() - date.getTimezoneOffset() * 60000,
  ).toISOString();
};

const initialDefaultItems_out = {
  educations: [
    {
      id: 1,
      content: {
        type: '',
        status: '',
        name: '',
        major: '',
        // isFinal : false,
        isFinal: true,
      },
    },
  ],
  workExperiences: [
    {
      id: 1,
      content: {
        type: '',
        name: '',
        startYear: undefined,
        startMonth: undefined,
        endYear: undefined,
        endMonth: undefined,
      },
    },
  ],
  certificates: [
    {
      id: 1,
      content: {
        type: '',
        name: '',
        acquisitionYear: undefined,
        acquisitionMonth: undefined,
      },
    },
  ],
};

const tabletTextSize = {
  large : "17px",
  normal : "12px"
};

const useResumeId = () => {
  const params = useParams();
  const location = useLocation();
  return params.resumeId || location.state?.resumeId || null;
};