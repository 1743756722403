import { Controller } from 'react-hook-form';
import TextArea from '../../custom/TextArea';

export default function MotivationForm({control, watch, errors, data}){
    const readOnly = (control == null || watch==null) || data!=null;

    let textLength;
    if (readOnly) {
      textLength = data ? data.length : 0 ;
    } else {
      textLength = watch('motivation') ? watch('motivation').length : 0;
    }
    return (
        <div className="flex flex-col mt-[40px] gap-[15px] content-stretch, m-auto">
            <div className="flex">
              <div className="text-[20px] font-[600]">지원 동기</div>
              <div className={`self-end ml-[10px] ${ textLength>500 ? 'text-red-600' : ''}`} >
                {textLength} / 500
              </div>
            </div>
            
            <div className="hidden tablet:block font-[300]">지원 동기를 적어주세요. (최대 500자) </div>
            { !readOnly ?
            <Controller
              name="motivation"
              control={control}
              render={({ field }) => (
                <TextArea
                  {...field}
                  error={errors?.motivation}
                  autoComplete="off"
                  wrapperClassName="h-[300px]"
                  className="w-full h-full tablet:text-[16px]"
                  placeholder="지원 동기를 500자 이내로 적어주세요."
                />
              )}
            />
            :
            <TextArea
              value={data}
              disabled
              autoComplete="off"
              wrapperClassName="h-[300px]"
              className="w-full h-full tablet:text-[16px]"
              placeholder="지원 동기를 500자 이내로 적어주세요."
            />
            }
        </div>
    );
}