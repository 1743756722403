import Button from '../../custom/Button';
import { useNavigate} from 'react-router-dom';
export default function SubmitButton({resumeId}) {
    const navigate = useNavigate();

    const handleEditResume = () => {
      // resumeId가 있을 때만 네비게이션 수행
      if (resumeId) {
        navigate(`/edit-resume/${resumeId}`);
      }
    };

    return (
        <div className="flex flex-1 mt-[40px] gap-[20px] tablet:flex-col tablet:gap-[10px]">
            <Button
              type="submit"
              onClick={handleEditResume}
              className="px-0 flex-1 mt-[40px] bg-[#440FDC] text-white font-[600] text-[18px]"
            >
              이력서 수정완료
            </Button>
            <Button
              type="button"
              onClick={() => {
                if (
                  window.confirm(
                    '이력서 작성을 취소하시면 지금까지 작성했던 내용이 모두 사라집니다.\n정말 취소하시겠습니까?',
                  )
                ) {
                  navigate(-1);
                }
              }}
              className="px-0 basis-[200px] mt-[40px] bg-[white] text-black font-[600] text-[18px]
                        tablet:m-0 tablet:basis-0"
            >
              작성취소
            </Button>
          </div>
    );
}