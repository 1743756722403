import Button from '../../custom/Button';
import Input from '../../custom/Input';
import HeaderWrapper, { TitleHeader } from '../Header';

export default function MobileNumberVerification() {
  return (
    <>
      <HeaderWrapper element={<TitleHeader title="본인인증" />} />
      <div className="mt-[60px] flex flex-col justify-center items-center">
        <div className="flex flex-col gap-[40px] items-start w-[520px]">
          <div className="text-[22px] font-[600] leading-[30px]">
            서비스 가입을 위해 <br />
            핸드폰 본인 인증을 진행해 주세요.
          </div>
          <div className="flex items-center content-center self-stretch flex-[1_0_0] flex-unwrap gap-[10px] justify-between">
            <div className="w-[105px] text-[18px]">핸드폰번호</div>
            <Input placeholder="번호를 입력해 주세요." />
            <Button className="flex-shrink-0">인증번호 전송</Button>
          </div>
        </div>
      </div>
    </>
  );
}
