import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { getAllPrograms } from '../../api';
import ProgramCardView from '../../components/program/ProgramCardView';

const size = 9;

export default function Programs() {
  const [programs, setPrograms] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    getAllPrograms(page, size)
      .then((res) => {
        setPrograms(res.data.content);
        setTotalPages(res.data.totalPages);
      })
      .catch((error) => console.log(error));
  }, [page]);

  const onPageChange = (event) => {
    setPage(event.selected);
  };

  return (
    <div className="w-full mt-[30px]">
      <div className="flex flex-wrap gap-[20px]">
        {programs?.map((program) => {
          return <ProgramCardView key={program.id} program={program} />;
        })}
      </div>
      <div className="w-full flex items-center justify-center text relative top-[50px]">
        <ReactPaginate
          className="flex items-center gap-[15px] mb-[50px]"
          previousLinkClassName={prevNextButtonClassName}
          nextLinkClassName={prevNextButtonClassName}
          pageLinkClassName="w-[46px] h-[46px] flex-[1_0_0] self-stretch rounded-[46px] flex items-center justify-center text-[#440FDC] select-none"
          activeLinkClassName="bg-[#F4F5FB]"
          nextLabel=">"
          previousLabel="<"
          pageCount={totalPages}
          pageRangeDisplayed={5}
          marginPagesDisplayed={1}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
}

const prevNextButtonClassName =
  'flex w-[40px] h-[50px] p-[10px] justify-center items-center gap-[10px] rounded-[10px] border-[1px] border-[#D3D3DF] bg-white select-none';
