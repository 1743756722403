import { useCallback, useState } from 'react';
import Input from '../../custom/Input';
import Button from '../../custom/Button';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';



export default function ResumeCeriticateForm ({items,onCreate, onChange, onDelete, categories, itemsError}) {
  const readOnly = onCreate == null || onChange == null || onDelete == null || itemsError == null
  return (
    <div className="flex mt-[40px] flex-col gap-[20px] items-start, self-stretch, m-auto">
            <div className='flex items-center'>
              <div className="text-[20px] basis-[80px] shrink-0 font-[600]">교육/자격</div>
              <div className="ml-[10px] font-[300]"> 하이어의 교육을 수료했거나 기타 자격사항, 또는 알려주시고 싶은 다른 내용이 있다면 등록해주세요. <br/> 자격증이 없어도 괜찮아요:)</div>
            </div>
            {readOnly && items.length === 0 && <div>작성한 교육/자격 항목이 없어요.</div>}
            {items.map((item,idx) => (
              !readOnly ?
              <ResumeCertificateItem
                id={item.id}
                key={item.id}
                content={item.content}
                onCertificateChange={onChange}
                onCertificateDelete={onDelete}
                category={categories}
                error={itemsError.certificates[item.id]}
              />
              :
              <ResumeCertificateItem
                id={idx}
                key={idx}
                readContent={item}
                category={categories}
              />
            ))}
            {!readOnly &&
            <Button
              type="button"
              onClick={onCreate}
              className="px-0 flex-1 border-0 bg-[#F4F5FB] text-[#440FDC] font-[600] text-[18px]"
            >
              추가 +
            </Button>
            }
          </div>
  )
}

function ResumeCertificateItem({id, content, onCertificateChange, onCertificateDelete, category, error, readContent }) {
  const readOnly = readContent != null
  const optionClassName=''
  const certificateOptions = category.map((item)=>({value:item,className:optionClassName}));
  // [
  //   {value:"컴퓨터", className: optionClassName},
  //   {value:"학문", className: optionClassName},
  //   {value:"예술", className: optionClassName},
  //   {value:"산업", className: optionClassName},
  //   {value:"생활", className: optionClassName},
  //   {value:"기타", className: optionClassName},
  // ];

  const yearOptions = Array(124)
    .fill()
    .map((element, index) => ({value:124 - index + 1900,label:`${124-index+1900}년`}));
  const monthOptions = Array(12)
    .fill()
    .map((element, index) => ({value:index+1, label: `${index+1}월`}) );


  return(
    <>
    <div className={`w-full text-[18px] tablet:text-[16px] flex flex-col items-stretch ${error && "!border-red-600 border-solid border-[1px] rounded-lg"}`}>
      
      <div className='flex items-center
                      w-[350px] border-[#B0B0BD] border-solid border-[1px] border-b-0 rounded-tl-lg rounded-tr-lg
                      tablet:w-full'>
          <div className='w-[105px] pl-[15px] font-[400] shrink-0 
                          tablet:basis-[80px] tablet:px-[12px]'>
            종류
          </div>
          <Dropdown 
            options={certificateOptions} 
            value = {!readOnly ? undefined : {value:readContent.type,label:readContent.type}} 
            disabled = {readOnly}
            className='!rounded-tl-lg !rounded-tr-lg grow basis-0' 
            controlClassName={'flex items-center !rounded-tl-lg !rounded-tr-lg !border-0 !pt-[14px] !pr-[15px] !pb-[15px] !pl-[15px]'  
                              + (readOnly ? " !bg-gray-50":"")}
            placeholderClassName='flex-1 text-center'
            menuClassName='!m-0 text-center'
            arrowClassName='!top-[25px] !right-[20px]'
            onChange={(option)=>onCertificateChange({target:{...option,name:"type"}},id)}
            placeholder="선택" 
          />
      </div>
      <div>
        <div className='flex'>
          <div className='flex-1'>
            <div className='flex items-center border-[#B0B0BD] border-solid border-[1px] border-b-0 w-full'>
                <div className='w-[105px] pl-[15px] font-[400] shrink-0 
                                tablet:basis-[80px] tablet:px-[12px]'>
                  내용
                </div>
                <Input
                  value={!readOnly? content?.name : readContent.name}
                  disabled={readOnly}
                  placeholder='내용을 입력해주세요' 
                  wrapperClassName='grow' className='border-0 w-full rounded-none tablet:text-[16px]'
                  autoComplete="off"
                  name="name" onChange={(e)=>onCertificateChange(e,id)}>
                </Input>
            </div>
            <div className='flex items-center border-[#B0B0BD] border-solid border-[1px] rounded-bl-lg tablet:rounded-bl-none'>
                <div className='w-[105px] pl-[15px] font-[400]
                                tablet:basis-[80px] shrink-0 tablet:px-[12px]'>
                  수료/<br/>취득일자
                </div>
                <div className='flex items-center w-[375px] tablet:w-full'>
                  <Dropdown
                    options={yearOptions}
                    disabled={readOnly}
                    value={!readOnly ? undefined : {
                      value: dateStringToYearMonth(readContent?.acquisitionDateTime).year,
                      label: `${dateStringToYearMonth(readContent?.acquisitionDateTime).year}`,
                    }}
                    className="basis-[225px] grow-[3]
                              border-[#B0B0BD] border-solid border-l-0 border-y-0 border-[1px]"
                    controlClassName={"flex items-center !rounded-tl-lg !rounded-tr-lg !border-0 !pt-[14px] !pr-[15px] !pb-[15px] !pl-[15px]"
                                      + (readOnly ? " !bg-gray-50":"")}
                    placeholderClassName="flex-1 text-center"
                    menuClassName="!m-0 text-center"
                    arrowClassName="!top-[25px] !right-[20px] tablet:!right-[10px]"
                    onChange={(option) => onCertificateChange({target:{...option, name:"acquisitionYear"}},id)}
                    // onChange={(option)=>onWorkExperienceChange({target:{...option,name:"type"}},id)}
                    placeholder="선택"
                  />
                  <Dropdown
                    options={monthOptions}
                    disabled={readOnly}
                    value={!readOnly ? undefined : {
                      value: dateStringToYearMonth(readContent?.acquisitionDateTime).month,
                      label: `${dateStringToYearMonth(readContent?.acquisitionDateTime).month}`,
                    }}
                    className="basis-[150px] grow-[2] 
                              border-[#B0B0BD] border-y-0 border-l-0 border-solid border-[1px]
                              tablet:border-r-0"
                    controlClassName={"flex items-center !rounded-tl-lg !rounded-tr-lg !border-0 !pt-[14px] !pr-[15px] !pb-[15px] !pl-[15px]"
                                      + (readOnly ? " !bg-gray-50":"")}
                    placeholderClassName="flex-1 text-center"
                    menuClassName="!m-0 text-center"
                    arrowClassName="!top-[25px] !right-[20px] tablet:!right-[10px]"
                    onChange={(option) => onCertificateChange({target:{...option,name:"acquisitionMonth"}},id)}
                    placeholder="선택"
                  />
                </div>
            </div>
          </div>
          {!readOnly &&
          <Button
            type='button' 
            onClick={()=>onCertificateDelete(id,"입력한 항목 하나를 삭제하시나요?\n이력서가 모두 지워지지는 않으니 걱정 마세요.")}
            className='rounded-l-none border-l-0 basis-[60px] border-[#B0B0BD] bg-[#440FDC] text-white text-[12px]
                      tablet:hidden'
            >
            삭제
          </Button>
          }
        </div>
        {!readOnly &&
        <Button
          type='button' 
          onClick={()=>{
            onCertificateDelete(id,"입력한 항목 하나를 삭제하시나요?\n이력서가 모두 지워지지는 않으니 걱정 마세요.")
          }}
          className=' w-full rounded-t-none bg-[#B0B0BD] border-[#B0B0BD] text-white text-[1px] hidden
                    tablet:text-[15px] tablet:!px-[2px] tablet:block'
          >
          삭제
        </Button>
        }
      </div>
    </div>
    {error && <div className="text-red-600">{error}</div>}
    </>
    )
  }


const dateStringToYearMonth = (dateString) => {
    const date = new Date(dateString);
    return {
      year:date.getFullYear(),
      month:date.getMonth() + 1
    }
};