import Input from '../../custom/Input';
import { Controller} from 'react-hook-form';

export default function UsernameForm({control,errors,currentUser}) {
    const readOnly = (control == null);
    return (
    <div className="flex items-center">
      <div className="w-[105px] shrink-0 font-[400]">이름</div>
      {!readOnly ?
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            disabled
            value={currentUser?.username}
            className='tablet:text-[16px]'
            autoComplete="off"
            placeholder="이름을 입력해 주세요."
          />
        )}
      />
      :
      <Input
        disabled
        value={currentUser?.name}
        className='tablet:text-[16px]'
        autoComplete="off"
        placeholder="이름을 입력해 주세요."
      />
      }
    </div>
    );
}