import Button from '../../custom/Button';
import Checkbox from '../../custom/Checkbox';

export default function AgreementItem({
  title,
  contentUrl,
  checked,
  onCheckClick,
}) {

  return (
    <div className={COLLAPSE_COMMON_CLASSNAME}>
    <Checkbox checked={checked} onClick={onCheckClick} />
    <div className="text-[18px] flex-[1_0_0]">{title}</div>
    <Button className="px-[10px] py-[7px]"><a href={contentUrl} target="_blank">전문보기</a></Button>
  </div>
  );
}

const COLLAPSE_COMMON_CLASSNAME =
  'flex w-[475px] tablet:w-[100%] h-[60px] items-center justify-center gap-[15px] py-[20px] px-[15px]';
