import Input from '../../custom/Input';
import Button from '../../custom/Button';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

export default function ResumeExperienceForm ({items, onCreate, onChange, onDelete,categories, itemsError}) {
  const readOnly = onCreate == null || onChange == null || onDelete == null || itemsError == null
  return (
    <div className="flex flex-col mt-[40px]  gap-[20px] items-start, self-stretch, m-auto">
      <div className='flex items-center'>
        <div className="text-[20px] basis-[100px] shrink-0 font-[600]">경력사항</div>
        <div className="ml-[10px] font-[300]"> 최신순으로 작성해주세요. <br/> 요식업, 어린이집 등 사람을 응대하는 업무를 하셨다면 ‘서비스’로 구분해주세요. </div>
      </div>
      {readOnly && items.length === 0 && <div>작성한 경력사항 항목이 없어요.</div>}
      {items.map((item,idx) => (
        !readOnly?
        <ResumeExperienceItem
          id={item.id}
          key={item.id}
          content={item.content}
          onWorkExperienceChange={onChange}
          onWorkExperienceDelete={onDelete}
          category={categories}
          error={itemsError.workExperiences[item.id]}
        />
        :
        <ResumeExperienceItem
          id={idx}
          key={idx}
          readContent={item}
          category={categories}
        />
      ))}
      { !readOnly &&
      <Button
        type="button"
        onClick={onCreate}
        className="px-0 flex-1 border-0 bg-[#F4F5FB] text-[#440FDC] font-[600] text-[18px]"
      >
        추가 +
      </Button>
      } 
    </div>  
  );
}

function ResumeExperienceItem({id, content, onWorkExperienceChange, onWorkExperienceDelete,category, error, readContent }) {
  const readOnly = readContent != null
  const optionClassName=''
  const jobOptions = category.map((item)=>({value:item,className:optionClassName}))
  // [
  //   {value:"행정·사무·전산", className: optionClassName},
  //   {value:"서비스", className: optionClassName},
  //   {value:"기타", className: optionClassName},
  // ];

  const yearOptions = Array(124)
    .fill()
    .map((element, index) => ({value:124 - index + 1900,label:`${124-index+1900}년`}));
  const monthOptions = Array(12)
    .fill()
    .map((element, index) => ({value:index+1, label: `${index+1}월`}) );

  return(
    <>
    <div className={`w-full text-[18px] tablet:text-[16px] flex flex-col items-stretch ${error && "!border-red-600 border-solid border-[1px] rounded-lg"}`}>
      
      <div className='flex items-center
                      w-[350px] border-[#B0B0BD] border-solid border-[1px] border-b-0  rounded-tl-lg rounded-tr-lg
                      tablet:w-full'>
          <div className='w-[105px] pl-[15px] font-[400]
                          tablet:basis-[70px] tablet:pl-[12px]'>
            구분
          </div>
          <Dropdown 
            options={jobOptions}
            value = {{value:content.type,label:content.type}} 
            disabled = {readOnly}
            className='!rounded-tl-lg !rounded-tr-lg grow basis-0' 
            controlClassName={'flex items-center !rounded-tl-lg !rounded-tr-lg !border-0 !pt-[14px] !pr-[15px] !pb-[15px] !pl-[15px]' + (readOnly ? " !bg-gray-50":"")}
            placeholderClassName='flex-1 text-center'
            menuClassName='!m-0 text-center'
            arrowClassName='!top-[25px] !right-[20px]'
            onChange={(option)=>onWorkExperienceChange({target:{...option,name:"type"}},id)}
            placeholder="선택" 
          />
      </div>
      <div>
        <div className='flex'>
          <div className='flex-1'>
            <div className='flex items-center border-[#B0B0BD] border-solid border-[1px] border-b-0 w-full'>
                <div className='w-[105px] pl-[15px] font-[400]
                                tablet:basis-[70px] tablet:pl-[12px]'>
                  내용
                </div>
                <Input
                  value={!readOnly? content?.name : readContent.occupation}
                  disabled={readOnly}
                  placeholder='내용을 입력해주세요'
                  wrapperClassName='grow' className='border-0 w-full rounded-none tablet:text-[16px]'
                  autoComplete="off" 
                  name="name" onChange={(e)=>onWorkExperienceChange(e,id)} >
                </Input>
            </div>
            <div className='flex items-stretch border-[#B0B0BD] border-solid border-[1px] rounded-bl-lg w-full
              tablet:rounded-b-none'>
                <div className='flex items-center w-[105px] pl-[15px] font-[400]
                                border-[#B0B0BD] border-solid
                                tablet:basis-[70px] tablet:pl-[12px] tablet:border-r-[1px]'>
                  기간
                </div>
                <div className='flex items-center w-full tablet:flex-col'>
                  <div className='flex items-center flex-1 tablet:w-full'>
                    <Dropdown
                      options={yearOptions}
                      disabled={readOnly}
                      value={!readOnly ? String(content.startYear)+"년" :{
                        value: dateStringToYearMonth(readContent?.startDateTime).year,
                        label: `${dateStringToYearMonth(readContent?.startDateTime).year}`,
                      }}

                      className="basis-[225px] grow-[3]
                                border-[#B0B0BD] border-solid border-r-[1px]"
                      controlClassName={"flex items-center !rounded-tl-lg !rounded-tr-lg !border-0 !pt-[14px] !pr-[15px] !pb-[15px] !pl-[15px]"  + (readOnly ? " !bg-gray-50":"")}
                      placeholderClassName="flex-1 text-center"
                      menuClassName="!m-0 text-center"
                      arrowClassName="!top-[25px] !right-[20px]"
                      onChange={(option) => onWorkExperienceChange({target:{...option, name:"startYear"}},id)}
                      placeholder="선택"
                    />
                    <Dropdown
                      options={monthOptions}
                      disabled={readOnly}
                      value={!readOnly ? String(content?.startMonth)+"월" : {
                        value: dateStringToYearMonth(readContent?.startDateTime).month,
                        label: `${dateStringToYearMonth(readContent?.startDateTime).month}`,
                      }}
                      className="basis-[150px] grow-[2] border-[#B0B0BD] border-solid border-r-[1px] tablet:border-r-0"
                      controlClassName={"flex items-center !rounded-tl-lg !rounded-tr-lg !border-0 !pt-[14px] !pr-[15px] !pb-[15px] !pl-[15px]" + (readOnly ? " !bg-gray-50":"")}
                      placeholderClassName="flex-1 text-center"
                      menuClassName="!m-0 text-center"
                      arrowClassName="!top-[25px] !right-[20px]"
                      onChange={(option) => onWorkExperienceChange({target:{...option,name:"startMonth"}},id)}
                      placeholder="선택"
                    />
                  </div>
                  <div className='px-[30px] 
                                  tablet:w-full tablet:text-center tablet:h-[30px] tablet:border-[#B0B0BD] tablet:border-y-[1px]'>~</div>
                  <div className='flex items-center flex-1 tablet:w-full'>
                    <Dropdown
                      options={yearOptions}
                      disabled={readOnly}
                      value={!readOnly ? String(content.endYear)+"년" : {
                        value: dateStringToYearMonth(readContent?.endDateTime).year,
                        label: `${dateStringToYearMonth(readContent?.endDateTime).year}`,
                      }}
                      className="basis-[225px] grow-[3] 
                                  border-[#B0B0BD] border-solid border-x-[1px] tablet:border-l-0"
                      controlClassName={"flex items-center !rounded-tl-lg !rounded-tr-lg !border-0 !pt-[14px] !pr-[15px] !pb-[15px] !pl-[15px]" + (readOnly ? " !bg-gray-50":"")}
                      placeholderClassName="flex-1 text-center"
                      menuClassName="!m-0 text-center"
                      arrowClassName="!top-[25px] !right-[20px]"
                      onChange={(option) => onWorkExperienceChange({target:{...option, name:"endYear"}},id)}
                      placeholder="선택"
                    />
                    <Dropdown
                      options={monthOptions}
                      disabled={readOnly}
                      value={!readOnly ? String(content?.endMonth)+"월"  : {
                        value: dateStringToYearMonth(readContent?.endDateTime).month,
                        label: `${dateStringToYearMonth(readContent?.endDateTime).month}`,
                      }}
                      className="basis-[150px] grow-[2] border-none"
                      controlClassName={"flex items-center !rounded-tl-lg !rounded-tr-lg !border-0 !pt-[14px] !pr-[15px] !pb-[15px] !pl-[15px]" + (readOnly ? " !bg-gray-50":"")}
                      placeholderClassName="flex-1 text-center"
                      menuClassName="!m-0 text-center"
                      arrowClassName="!top-[25px] !right-[20px]"
                      onChange={(option) => onWorkExperienceChange({target:{...option,name:"endMonth"}},id)}
                      placeholder="선택"
                    />
                  </div>
                </div>
            </div>
          </div>
          {!readOnly && 
          <Button
            type='button' 
            onClick={()=>onWorkExperienceDelete(id,"입력한 경험 항목 하나를 삭제하시나요?\n이력서가 모두 지워지지는 않으니 걱정 마세요.")}
            className='rounded-l-none border-l-0 basis-[60px] shrink-0 border-[#B0B0BD] bg-[#440FDC] text-white text-[12px]
                       tablet:hidden'
            >
            삭제
          </Button>
          }
        </div>
        {!readOnly &&
        <Button
          type='button' 
          onClick={()=>{
            onWorkExperienceDelete(id,"입력한 경험 항목 하나를 삭제하시나요?\n이력서가 모두 지워지지는 않으니 걱정 마세요.")
          }}
          className=' w-full rounded-t-none bg-[#B0B0BD] border-[#B0B0BD] text-white text-[12px] hidden
                    tablet:text-[15px] tablet:!px-[2px] tablet:block'
          >
          삭제
        </Button>
        } 
      </div>
    </div>
    {error && <div className="text-red-600">{error}</div>}
    </>
    )
  }


const dateStringToYearMonth = (dateString) => {
    const date = new Date(dateString);
    return {
      year:date.getFullYear(),
      month:date.getMonth() + 1
    }
};