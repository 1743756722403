import { createBrowserRouter } from 'react-router-dom';
import LoginModal from './components/LoginModal';
import MobileNumberVerification from './components/sigup/MobileNumberVerification';
import RootWrapper from './custom/wrapper/RootWrapper';
import Home from './pages/Home';
import Redirect from './pages/oauth2/Redirect';
import DetailedProgramPage from './pages/programs/DetailedProgramPage';
import Programs from './pages/programs/Programs';
import Resume from './pages/resumes/Resume';
import EditResume from './pages/resumes/EditResume';
import ResumeForm from './pages/resumes/ResumeForm';
import Agreement from './pages/signup/Agreement';
import Complete from './pages/signup/Complete';
import InfoInput from './pages/signup/InfoInput';
import MyPage from './pages/mypage/MyPage';
import MyInfo from './pages/mypage/MyInfo';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootWrapper />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/programs',
        element: <Programs />,
      },
      {
        path: 'programs/:id',
        element: <DetailedProgramPage />,
      },
    ],
  },
  {
    path: '/signup',
    children: [
      {
        path: 'agreement',
        element: <Agreement />,
      },
      {
        path: 'mobileNumberVerification',
        element: <MobileNumberVerification />,
      },
      {
        path: 'infoinput',
        element: <InfoInput />,
      },
      {
        path: 'complete',
        element: (
          <>
            <Complete />
            <LoginModal />
          </>
        ),
      },
    ],
  },
  {
    path: '/oauth2',
    children: [
      {
        path: 'redirect',
        element: <Redirect />,
      },
    ],
  },
  {
    path: '/programs/:programId/resume-form',
    element: <ResumeForm />,
  },
  // temporary path
  {
    path : '/resumes/:resumeId',
    element : <Resume/>
  },
  {
    path : '/resumes',
    element : <Resume/>
  },
  {
    path : '/edit-resume/:resumeId',
    element : <EditResume/>
  },
  {
    path : '/edit-resume',
    element : <EditResume/>
  },
  {
    path: '/myPage',
    children: [
      {
        path: '',
        element: <MyPage />
      },
      {
        path: "myInfo",
        element: <MyInfo />
      }
    ]
  },
]);

export default router;
