import {useState, useEffect, useCallback} from 'react';
import { getResumeAgreements } from "../../api";

export default function useFormAgreements () {
    const DISPLAY_FIELD_NAME = {
        'certificates' : '교육/자격'
    }

    // get agreement items from api
    const [agreements, setAgreements] = useState([
        {title:"[필수] 개인정보 수집 및 이용안내",contentUrl:"",required:true,requiringFields:[],id:1},
        {title:"[선택] 개인정보 수집 및 이용안내",contentUrl:"",required:false,requiringFields:[],id:2},
    ])

    useEffect(()=>{
        getResumeAgreements()
        .then(res => {
          setAgreements(res.data);
        })
        .catch(e => console.log(e))
    },[])

    // form input agreedAgreement
    const [agreedAgreementIds, setAgreedAgreementIds] = useState(new Set());

    // for validation
    const [agreementErrors,setAgreementErrors] = useState({});

    // data parameter is converted data before send a request
    const validateAgreements = useCallback((data)=>{
        let hasError=false;
        const {allAgreementIds, requiredAgreementIds} = agreements.reduce((result,item)=>{
            result.allAgreementIds.add(item.id)
            if (item.required) {
                result.requiredAgreementIds.add(item.id)
            }
            return result;
        }, {allAgreementIds:new Set(),requiredAgreementIds:new Set()})

        // if not agreeed on required agreement
        requiredAgreementIds.forEach((id)=>{
            if ( !agreedAgreementIds.has(id)){
                setAgreementErrors((errors)=>({...errors, [id]:"이력서를 제출하려면 필수 동의항목을 체크해야 합니다."}));
                hasError = true;
            }
        });

        // TODO : if input requires optional agreement

        allAgreementIds.forEach((id)=>{
            const agreement = agreements.find((item)=>item.id===id);
            const requiringFields = agreement.requiringFields || [];
            requiringFields.forEach((fieldName)=>{
                const fieldValue = data[fieldName]
                if (Array.isArray(data[fieldName])) { // if field is an array
                    if (!agreedAgreementIds.has(id) && fieldValue.length!==0){
                        setAgreementErrors((errors)=>({...errors, [id]:`${DISPLAY_FIELD_NAME[fieldName] || fieldName }을(를) 입력하려면 해당 동의항목을 체크해야 합니다.`}));
                        hasError = true;
                    }
                } else {
                    //TODO : for another type of field
                }
            })
        });
        return !hasError;
    }, agreements, agreedAgreementIds)

    return {
        agreements,
        agreedAgreementIds, setAgreedAgreementIds,
        agreementErrors,validateAgreements
    };
}