import { twMerge } from 'tailwind-merge';
export default function Input(props) {
  const className = twMerge(
    'items-center pt-[14px] pr-[15px] pb-[15px] pl-[15px] border-[#B0B0BD] border-solid border-[1px] rounded-lg text-[18px] w-[360px] tablet:w-[100%]',
    props.className
  );
  return (
    <>
      <div className={props.wrapperClassName}>
        <input
          {...props}
          className={`${className} ${props?.error ? 'error' : ''}`}
        />
        {props?.error && <div className="error">{props.error?.message}</div>}
      </div>
    </>
  );
}
