import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function CustomToastProvider({ children }) {
  return (
    <>
        {children}
      <ToastContainer position="top-right" autoClose={5000} />
    </>
  );
}
