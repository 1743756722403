import { Controller } from 'react-hook-form';
import { useState } from 'react';
import Input from '../../custom/Input';

export default function TitleForm({control, errors, data}){
    const readOnly = (control == null) || data!=null;

    return (
        <div className="flex flex-col mt-[40px] gap-[15px] content-stretch, m-auto">
            <div className="flex-1 text-[20px] font-[600]">이력서 제목</div>
            <div className="hidden text- tablet:block font-[300]">일을 하고 싶은 이유를 편하게 입력해주세요.<br/>(최대 30자) </div>
            { !readOnly ?
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  error={errors?.title}
                  autoComplete="off"
                  className="flex-1 w-full"
                  placeholder="일을 하고 싶은 이유를 한 줄로 편하게 입력해주세요."
                />
              )}
            />
            :
            <Input
              value={data}
              disabled
              autoComplete="off"
              className="flex-1 w-full"
              placeholder="일을 하고 싶은 이유를 한 줄로 편하게 입력해주세요."
            />
            }
        </div>
    );
}