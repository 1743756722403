import Input from '../../custom/Input';
import { Controller} from 'react-hook-form';

export default function AddressForm({control,errors,data}) {
    const readOnly = (control == null)
    return (
    <div className="flex items-center w-[100%] ">
      <div className="w-[105px] tablet:w-[70px] shrink-0 font-[400]">주소</div>
      <div className="flex flex-col gap-[5px] flex-1">
        <div className="ml-[10px] tablet:ml-0 font-[300]">(예: 서울특별시 종로구 OO로 00) </div>
        {!readOnly ?
        <Controller
          name="addressHead"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={errors?.addressHead}
              className="w-4/5 tablet:text-[16px]"
              autoComplete="off"
              placeholder="주소를 입력해 주세요."
            />
          )}
        />
        :
        <Input
              value={data.address} disabled
              className="w-4/5 tablet:text-[16px]"
              autoComplete="off"
              placeholder="주소를 입력해 주세요."
        />
        }

        { !readOnly  ?<div className="ml-[10px] tablet:ml-0 font-[300]">(예: OOO아파트) </div> : null}
        { !readOnly ?
        <Controller
          name="addressDetail"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              error={errors?.addressDetail}
              autoComplete="off"
              className="w-full tablet:text-[16px]"
              placeholder="상세주소를 입력해 주세요."
            />
          )}
        />
        :
        <Input
            value={data.addressDetail} disabled
            autoComplete="off"
            className="w-full tablet:text-[16px]"
            placeholder="상세주소를 입력해 주세요."
        />
        }
      </div>
    </div>
    );
}