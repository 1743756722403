import { twMerge } from 'tailwind-merge';
export default function TextArea(props) {
  const className = twMerge(
    'items-center resize-none pt-[14px] pr-[15px] pb-[15px] pl-[15px] border-[#B0B0BD] border-solid border-[1px] rounded-lg text-[18px] w0-[360px]  tablet:w-[100%]',
    props.className
  );

  const {wrapperClassName,error,...textAreaProps}  = props;
  return (
    <>
      <div className={wrapperClassName}>
        <textarea
          {...textAreaProps}

          row={100}

          className={`${className} ${error ? 'error' : ''}`}
        />
        {error && <div className="error">{error?.message}</div>}
      </div>
    </>
  );
}
