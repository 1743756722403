import { useLocation, useNavigate } from 'react-router-dom';
import HeaderWrapper, { TitleHeader } from '../../components/Header';
import Button from '../../custom/Button';
import Input from '../../custom/Input';
import Radio from '../../custom/Radio';
import { kakaoSignup } from '../../api';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export default function InfoInput() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const isOAuthSignup = state && state.isOAuthSignup;
  const agreedAgreementIds = state && state.agreedAgreementIds;
  const email = state && state.email;

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('이름은 필수로 입력해 주셔야 합니다.'),
    gender: Yup.string().required('성별은 필수로 입력해 주셔야 합니다.'),
    birthDate: Yup.string()
      .required('생년월일은 필수로 입력해 주셔야 합니다.')
      .matches(
        /^\d{4}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/,
        '생년월일은 YYYYMMDD 형태여야 합니다.',
      ),
  });

  const onSignupComplete = (data) => {
    data.agreedAgreementIds = agreedAgreementIds;
    data.birthDate = moment(data.birthDate, 'YYYYMMDD').format(
      'YYYY-MM-DDTHH:mm:ss',
    );
    kakaoSignup(data)
      .then(() => {
        navigate('/signup/complete');
      })
      .catch((error) => console.log(error));
  };
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { control, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  return (
    <>
      <HeaderWrapper element={<TitleHeader title="회원가입" />} />
      <div className="flex mt-[60px] items-center justify-center tablet:px-[10px]">
        <div className="flex flex-col items-start gap-[40px]">
          <div className="text-[22px] font-[600] leading-[30px]">
            가입에 필요한 정보를 입력해 주세요
          </div>
          <form onSubmit={handleSubmit(onSignupComplete)}>
            <div className="flex flex-col items-start gap-[20px] self-stretch">
              {isOAuthSignup ? (
                ''
              ) : (
                <>
                  <div className={LINE_CONTAINER_COMMON_CLASSNAME}>
                    <div className="w-[105px] text-[18px] font-normal">
                      아이디
                    </div>
                    <Input
                      className="flex-1"
                      placeholder="아이디를 입력해 주세요."
                    />
                    <Button>중복확인</Button>
                  </div>
                  <div className={LINE_CONTAINER_COMMON_CLASSNAME}>
                    <div className="w-[105px] text-[18px] font-normal">
                      비밀번호
                    </div>
                    <Input placeholder="비밀번호를 입력해 주세요." />
                  </div>
                  <div className={LINE_CONTAINER_COMMON_CLASSNAME}>
                    <div className="w-[105px] text-[18px] font-normal">
                      비밀번호 확인
                    </div>
                    <Input placeholder="비밀번호를 한 번 더 입력해 주세요." />
                  </div>
                </>
              )}
              <div className={LINE_CONTAINER_COMMON_CLASSNAME}>
                <div className="w-[105px] text-[18px] font-normal">이름</div>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <Input {...field} error={errors?.username} placeholder="이름을 입력해 주세요." />
                  )}
                />
              </div>
              <div className={LINE_CONTAINER_COMMON_CLASSNAME}>
                <div className="w-[105px] text-[18px] font-normal">성별</div>
                <div>
                  <div className='flex gap-[20px]'>

                    <div className="flex gap-[10px] items-center">
                      <Controller
                        name="gender"
                        control={control}
                        render={({ field }) => (
                          <Radio
                            {...field}
                            name="gender"
                            id="male"
                            value="MALE"
                          />
                        )}
                      />
                      <label className="text-[18px]" for="male">
                        남자
                      </label>
                    </div>
                    <div className="flex gap-[10px] items-center">
                      <Controller
                        name="gender"
                        control={control}
                        render={({ field }) => (
                          <Radio
                            {...field}
                            name="gender"
                            id="female"
                            value="FEMALE"
                          />
                        )}
                      />
                      <label className="text-[18px]" htmlFor="female">
                        여자
                      </label>
                    </div>
                  </div>
                  {errors?.gender && <div className="error">{errors.gender?.message}</div>}
                </div>
              </div>
              <div className={LINE_CONTAINER_COMMON_CLASSNAME}>
                <div className={`w-[105px] text-[18px] font-normal`}>
                  생년월일
                </div>
                <Controller
                  name="birthDate"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        error={errors?.birthDate}
                        placeholder="YYYYMMDD"
                      />
                    </>
                  )}
                />
              </div>
              <div className={LINE_CONTAINER_COMMON_CLASSNAME}>
                <div className="w-[105px] text-[18px] font-normal">이메일</div>
                <Input
                  value={email}
                  disabled={isOAuthSignup}
                  placeholder="아이디@example.com"
                />
              </div>
              <Button
                type="submit"
                className="w-[100%] bg-[#440FDC] text-white font-[600] text-[18px]"
              >
                회원가입
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

const LINE_CONTAINER_COMMON_CLASSNAME =
  'flex items-center content-center gap-[10px] flex-[1_0_0] flex-wrap w-[475px] tablet:w-[100%]';
