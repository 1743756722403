import { useNavigate } from "react-router-dom";
import Button from "../../custom/Button";
import { useDispatch } from "react-redux";
import { open } from "../../reducers/loginModal/loginModalReducer";

export default function Complete() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToHomePage = () => {
    navigate("/");
  }

  const goToLogin = () => {
    dispatch(open());
  }

  return (
    <div className="flex h-screen items-center justify-center bg-white">
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col w-[479px] tablet:w-[100%] gap-[30px] pt-[40px] px-[60px] pb-[60px]">
          <img src="/images/signup_complete_img.png" className="w-[330px] tablet:w-[300px]" />
          <div className="flex flex-col gap-[20px]">
            <div className="text-[24px] font-[700] text-center">
              가입을 축하해요!
            </div>
            <div className="text-center text-[18px] text-[#535863]">
              Higher-re에 오신 것을 환영합니다. <br />
              퇴직한 당신을 위한 양질의 일자리를 찾아드려요.
            </div>
          </div>
        </div>
        <div className="w-[100%] flex p-[10px] items-start self-stretch gap-[10px]">
          <Button className="pt-[19px] pb-[20px] flex-[1_0_0] text-[20px] font-[600]" onClick={goToHomePage}>홈으로</Button>
          <Button className="pt-[19px] pb-[20px] flex-[1_0_0] text-white bg-[#440FDC] text-[20px] font-[600]" onClick={goToLogin}>로그인 하기</Button>
        </div>
      </div>
    </div>
  );
}
