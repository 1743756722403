import { MenuOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { logout } from '../api';
import { getProgram } from '../api';
import { selectAuth } from '../reducers/auth/authReducer';
import { open } from '../reducers/loginModal/loginModalReducer';
import { useState,useEffect } from 'react';

export default function HeaderWrapper({ element }) {
  return (
    <div className="sticky top-[0px] bg-white z-[1000]">
      {element}
      <div className="w-full sticky top-[50px] flex-shrink-0 border-b-[1px] border-b-[#D3D3DF]"></div>
    </div>
  );
}

export function HomeHeader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  
  const openModal = () => {
    dispatch(open());
  };

  const user = useSelector(selectAuth);

  const items = [
    {
      key: '1',
      label: <MyPageButton />,
    },
    {
      key: '2',
      label: (
        <>
          <LogoutButton />
        </>
      ),
    },
  ];

  const menus = [
    {
      key: '1',
      renderCondition: true,
      elements: [<CompanyServiceButton />],
    },
    {
      key: '2',
      renderCondition: user,
      elements: [<MyPageButton />, <LogoutButton />],
    },
  ];

  const onApply = () => {
    if (!user) {
      alert('로그인 후 이용 가능합니다.');
      dispatch(open());
    } else {
      navigate(`/programs/${params.id}/resume-form`);
    }
  };

  return (
    <div>
      <div className="flex flex-row items-start  justify-between gap-[30px] tablet:gap-0 w-[1000px] tablet:w-[100%] h-[60px] m-auto">
        {/* <div className="w-[175px] h-[60px] flex px-[8px] py-[10px] items-center">
        <img
          onClick={() => navigate('/')}
          className="h-auto w-[138px] cursor-pointer"
          src="/images/HIGHER-re.svg"
          alt="회사 로고"
        />
      </div> */}
        <div className="h-[60px] flex items-center px-[15px] py-[13px]">
          <img
            onClick={() => navigate('/')}
            className="h-auto cursor-pointer w-full"
            src="/images/HIGHER-re-beta.svg"
            alt="회사 로고"
          />
        </div>
        <HeaderMenus className="flex tablet:hidden" />
        <div className="flex h-[60px] py-[13px] px-[15px] flex-col justify-between items-center">
          <div className="flex gap-[10px]">
            {user ? (
              <div className="w-[100px] h-[35px] flex justify-center items-center pt-[14px] pl-[15px] pb-[15px] pr-[15px] gap-[10px] text-[16px] font-normal">
                <div className="hidden tablet:flex">{`${user?.username}님!`}</div>
                <Dropdown
                  className="tablet:hidden"
                  menu={{ items }}
                  placement="bottom"
                  arrow={{ pointAtCenter: true }}
                >
                  <div>{`${user?.username}님!`}</div>
                </Dropdown>
              </div>
            ) : (
              <>
                <button
                  className="w-[80px] h-[35px] flex justify-center items-center pt-[14px] pl-[15px] pb-[15px] pr-[15px] gap-[10px] rounded-lg border-[1px] bg-[#FFF] border-[#D3D3DF] text-[16px] font-normal"
                  onClick={openModal}
                >
                  로그인
                </button>
              </>
            )}
            <button className="w-[140px] h-[35px] flex justify-center items-center pt-[14px] pl-[15px] pb-[15px] pr-[15px] gap-[10px] rounded-lg border-[1px] bg-[#FFF] border-[#D3D3DF] text-[16px] font-normal tablet:hidden" onClick={onApply}>
              이력서등록
              {/* <CompanyServiceButton /> */}
            </button>
            <HamburgerMenu menus={menus} />
          </div>
        </div>
      </div>
      <HeaderMenus className="hidden tablet:flex" />
    </div>
  );
}

const tabs = [
  {
    title: 'HOME',
    path: '/',
    diabled: false,
  },
  {
    title: '프로그램',
    path: '/programs',
    disabled: false,
  },
  // {
  //   title: '채용정보',
  //   extLink: 'https://naver.com',
  //   disabled: false,
  // },
  {
    title: '문의하기',
    extLink: 'https://higherrebiz.imweb.me/contact',
    disabled: false,
  },
];

function CompanyServiceButton() {
  return <a href="https://higherrebiz.imweb.me">기업 서비스</a>;
}

function PostResumeButton() {

}

function HeaderMenus(props) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div
      className={twMerge(
        'h-[60px] flex flex-shrink-0 bg-white',
        props.className,
      )}
    >
      {tabs.map((tab) => {
        return (
          <div
            className={`flex w-[130px] text-center p-[20px] justify-center items-center gap-[10px] flex-[1_0_0] text-[20px] font-[600] tablet:text-[16px] cursor-pointer ${
              tab.disabled ? 'text-gray-300 ' : ''
            } ${
              tab.path === location.pathname
                ? 'border-b-[3px] border-[#440FDC] text-[#440FDC] font-[600]'
                : ''
            }`}
            onClick={
              tab?.path
                ? () => navigate(tab.path)
                : tab?.extLink
                ? () => window.open(tab?.extLink, '_blank')
                : null
            }
          >
            {tab.title}
            {tab.disabled ? (
              <>
                <br />
                (출시 예정)
              </>
            ) : (
              ''
            )}
          </div>
        );
      })}
    </div>
  );
}

function LogoutButton() {
  const onLogout = () => {
    logout()
      .then((_) => {
        document.location.reload();
      })
      .catch((e) => console.log(e));
  };

  return <div onClick={onLogout}>로그아웃</div>;
}

function MyPageButton() {
  const navigate = useNavigate();

  const moveToMyPage = () => {
    navigate('/myPage');
  };

  return <div onClick={moveToMyPage}>마이페이지</div>;
}

export function TitleHeader({ title, backUrl, hasSideMenu, menus }) {
  const navigate = useNavigate();

  const onMoveBack = () => {
    if (backUrl) {
      navigate(backUrl);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="flex flex-row items-center justify-between gap-[30px] self-stretch w-[1000px] tablet:w-[100%] h-[60px] m-auto px-[15px]">
      <div className="flex gap-[10px] w-[100%]">
        <div className="flex justify-center items-center h-[60px]">
          <button className="w-[30px] h-[30px]" onClick={onMoveBack}>
            <img src="/images/left_arrow.svg" />
          </button>
        </div>
        <div className="flex flex-grow-[1] justify-center items-center text-[20px] font-[600]">
          {title}
        </div>
      </div>
      <div className="">{hasSideMenu ? <HamburgerMenu menus={menus}/> : ''}</div>
    </div>
  );
}

/**
 * menus format:
 * menus = [
 *  [ // groups
 *    {
 *      key: '',
 *      renderCondition: ,
 *      elements: []
 *    }
 *  ],
 * ]
 */
function HamburgerMenu({ menus }) {
  const [collapsed, setCollapsed] = useState(false);
  const user = useSelector(selectAuth);

  const toggle = () => {
    setCollapsed((prev) => !prev);
  };

  return (
    <div className="hidden tablet:flex">
      <div onClick={toggle} className="flex justify-center items-center p-1">
        <MenuOutlined />
      </div>
      {collapsed && (
        <div className="fixed z-[100] top-[60px] left-[0px] w-[100vw] h-[100vh] bg-white px-[20px]">
          {menus?.map((menuGroup) => {
            return (
              <>
                {menuGroup?.renderCondition && <div key={menuGroup.key} className="flex flex-col gap-[10px] py-[20px] border-solid border-b-[1px]">
                  {menuGroup?.elements.map((element) => {
                    return <div className="border-solid">{element}</div>;
                  })}
                </div>}
              </>
            );
          })}
        </div>
      )}
    </div>
  );
}
