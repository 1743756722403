import Radio from '../../custom/Radio';
import { Controller} from 'react-hook-form';

export default function GenderForm({control,errors,currentUser}) {
    const readOnly = (control == null);
    return (
    <div className="flex items-center jusitfy-start w-[360px]">
      <div className="w-[105px] shrink-0 font-[400]">성별</div>
      <div className="flex gap-[20px]">
        <div className="flex gap-[10px] items-center">
          {!readOnly ?
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <Radio
                {...field}
                name="gender"
                id="male"
                value="MALE"
                disabled
                checked={currentUser?.gender === 'MALE'}
              />
            )}
          />
          :
          <Radio
            name="gender"
            id="male"
            value="MALE"
            disabled
            checked={currentUser?.gender === 'MALE'}
          />
          }
          <label className="shrink-0" for="male">
            남자
          </label>
        </div>
        <div className="flex gap-[10px] items-center">
          {!readOnly ?
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <Radio
                {...field}
                name="gender"
                id="female"
                value="FEMALE"
                disabled
                checked={currentUser?.gender === 'FEMALE'}
              />
            )}
          />
          :
          <Radio
            name="gender"
            id="female"
            value="FEMALE"
            disabled
            checked={currentUser?.gender === 'FEMALE'}
          />
          }
          <label className="shrink-0" htmlFor="female">
            여자
          </label>
        </div>
      </div>
    </div>
    );
}