import { createSlice } from "@reduxjs/toolkit";

export const loginModalSlice = createSlice({
  name: 'loginModal',
  initialState: {
    isOpen: false
  },
  reducers: {
    open: state => {
      state.isOpen = true
    },
    close: state => {
      state.isOpen = false
    }
  }
})

export const { open, close } = loginModalSlice.actions;

export const selectLoginModal = state => state.loginModal.isOpen;

export default loginModalSlice.reducer