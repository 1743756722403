import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { postResumeAndApply } from '../../api';

import HeaderWrapper, { TitleHeader } from '../../components/Header';
import TitleForm from '../../components/resumes/TitleForm';
import BasicInfoForm from '../../components/resumes/BasicInfoForm';
import ResumeEducationForm from '../../components/resumes/ResumeEducationForm';
import ResumeExperienceForm from '../../components/resumes/ResumeExperienceForm';
import ResumeCertificateForm from '../../components/resumes/ResumeCertificateForm';
import ComputerAbilityForm from '../../components/resumes/ComputerAbilityForm';
import DesiredWorkTypeForm from '../../components/resumes/DesiredWorkTypeForm';
import DesiredWorkRegionForm from '../../components/resumes/DesiredWorkRegionForm';
import AgreementForm from '../../components/resumes/AgreementForm';
import SubmitButton from '../../components/resumes/SubmitButton';

import useFormItems from '../../hooks/resumes/useFormItems';
import useItemValidation from '../../hooks/resumes/useItemValidation';
import useFormAgreements from '../../hooks/resumes/useFormAgreements';
import useFormSubcategories from '../../hooks/resumes/useFormSubcatgories';
import useCurrentUser from '../../hooks/useCurrentUser';
import useResumeData from '../../hooks/resumes/useResumeData';
import MotivationForm from '../../components/resumes/MotivationForm';

export default function Resume() {
  
  const resumeId = useResumeId();

  const {agreements} = useFormAgreements();
  const formSubcategories = useFormSubcategories();
  const resumeData = useResumeData(resumeId);

  // console.log("Resume.js : ", resumeId)
  
  return (
    <div className="flex-col justify-center tablet:w-[100%] ">
        <HeaderWrapper element={<TitleHeader title="이력서 조회" />} />
        { resumeData!=null ?
        <form
          className="w-[1000px] tablet:w-[100%] tablet:px-[30px] mb-[40px] m-auto"
        >
          <TitleForm
            data={resumeData.title}
          />

          <MotivationForm
            data={resumeData.motivation}
          />
          <BasicInfoForm
            data={{
              address: resumeData.address,
              addressDetail : resumeData.addressDetail,
              phone : resumeData.phone,
              email : resumeData.email
            }}
            currentUser={resumeData.user} 
          />
          <ResumeEducationForm
            items={resumeData.educations}
            categories={formSubcategories.educationTypes}
          />
          
          <ResumeExperienceForm
            items={resumeData.workExperiences}
            categories={formSubcategories.workExperienceTypes}
          />
          
          <ResumeCertificateForm
            items={resumeData.certificates}
            categories={formSubcategories.certificateTypes}
          />
         
          <ComputerAbilityForm
            data={resumeData.computerAbility}
          />
          <DesiredWorkTypeForm
            data={resumeData.workType}
          />
          
          <DesiredWorkRegionForm 
            data={{
              region : resumeData.workRegion,
              city : resumeData.workRegionCity
            }}
          />
          <AgreementForm
            agreements={agreements}
            data={resumeData.agreedAgreementIds}
          />
        </form>
        :
        <div className="ml-[50px] mt-[20px]">존재하지 않는 이력서입니다.</div>
        }
    </div>
  );
}

const initialDefaultItems = {
  educations: [
    {
      id: 1,
      content: {
        type: '',
        status: '',
        name: '',
        major: '',
        // isFinal : false,
        isFinal: true,
      },
    },
  ],
  workExperiences: [
    {
      id: 1,
      content: {
        type: '',
        name: '',
        startYear: undefined,
        startMonth: undefined,
        endYear: undefined,
        endMonth: undefined,
      },
    },
  ],
  certificates: [
    {
      id: 1,
      content: {
        type: '',
        name: '',
        acquisitionYear: undefined,
        acquisitionMonth: undefined,
      },
    },
  ],
};

const useResumeId = () => {
  const params = useParams();
  const location = useLocation();
  return params.resumeId ? params.resumeId : location.state?.resumeId
}

