import { useEffect, useState } from "react";
import { getResumeData } from "../../api";

export default function useResumeData(resumeId) {

  const [resumeData, setResumeData] = useState(initialData);

  useEffect(() => {
    getResumeData(resumeId)
      .then(res => {
        setResumeData(res.data);
      })
      .catch(e => {
        console.log(e);
        if (e.response.status === 404) {
          setResumeData(null);
        }        
      
      })
  }, [])

  return resumeData;
}

const initialData = {
  id: 0,
  title : "",
  phone: "",
  email: "",
  address: "",
  computerAbility: 1,
  workType: "",
  workRegion: "",
  workRegionCity: "",
  createdDateTime: Date.now(),
  updatedDateTime: Date.now(),
  user: {
    name: "",
    gender: "MALE",
    birthDateTime: Date.now()
  },
  educations: [
    {
      degree: "",
      status: "",
      school: "",
      major: "",
      isFinal: true
    }
  ],
  "workExperiences": [
    {
      "occupationType": "",
      "occupation": "",
      "startDateTime": Date.now(),
      "endDateTime": Date.now()
    }
  ],
  "certificates": [
    {
      "type": "",
      "name": "",
      "acquisitionDateTime": Date.now()
    }
  ],
  agreedAgreementIds: [
    0
  ],
  "application": {
    "programId": 0,
    "applicationDateTime": Date.now()
  },
  "applied": true
}