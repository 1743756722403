import moment from 'moment';
import HeaderWrapper, { TitleHeader } from '../../components/Header';
import useCurrentUser from '../../hooks/useCurrentUser';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth, set } from '../../reducers/auth/authReducer';
import Input from '../../custom/Input';
import Radio from '../../custom/Radio';
import Button from '../../custom/Button';
import { useEffect, useState } from 'react';
import { updateUserInfo } from '../../api';

export default function MyInfo() {
  const user = useSelector(selectAuth);

  const [email, setEmail] = useState();
  const [emailUpdateInProgress, setEmailUpdateInProgress] = useState(false);

  useEffect(() => {
    setEmail(user?.email);
  }, []);

  const onUpdateEmail = () => {
    if (emailUpdateInProgress && !validateEmail(email)) {
      alert('가능한 이메일 형식이 아닙니다!');
    } else if (emailUpdateInProgress) {
      const result = window.confirm(`이메일을 ${email}로 변경하시겠습니까?`);

      if (result) {
        updateUserInfo(user?.id, {
          email,
        })
          .then(() => {
            window.location.reload();
          })
          .catch((e) => console.log(e));
      }
    } else {
      setEmailUpdateInProgress((prev) => !prev);
    }
  };

  return (
    <>
      <HeaderWrapper element={<TitleHeader title="내 정보 확인" />} />
      <div className="flex justify-center mt-[30px] text-[18px] tablet:px-[20px]">
        <div className="flex flex-col gap-[30px] w-[475px]">
          <div className="flex gap-[10px] items-center">
            <div className="w-[105px]">이름</div>
            <div>
              <Input placeholder={user?.username} disabled />
            </div>
          </div>
          <div className="flex gap-[10px] items-center">
            <div className="w-[105px]">성별</div>
            <div className="flex gap-[20px]">
              <div className="flex items-center gap-[10px]">
                <Radio checked={user?.gender === 'MALE'} />
                <div>남자</div>
              </div>
              <div className="flex items-center gap-[10px]">
                <Radio checked={user?.gender === 'FEMALE'} />
                <div>여자</div>
              </div>
            </div>
          </div>
          <div className="flex gap-[10px] items-center">
            <div className="w-[105px]">생년월일</div>
            <div>
              <Input
                placeholder={moment(user?.birthDate).format('YYYY.MM.DD')}
                disabled
              />
            </div>
          </div>
          <div className="flex gap-[10px] items-center">
            <div className="w-[105px]">이메일</div>
            <div className="flex flex-wrap gap-[10px]">
              <Input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onUpdateEmail();
                  }
                }}
                className="max-w-[250px]"
                value={email}
                disabled={!emailUpdateInProgress}
              />
              <Button onClick={onUpdateEmail}>
                {emailUpdateInProgress ? '변경완료' : '변경하기'}
              </Button>
            </div>
          </div>
          <div className="flex gap-[10px] items-center">
            <div className="w-[105px]">전화번호</div>
            <div>
              <Input placeholder={user?.phoneNumber} disabled />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};
