import Input from '../../custom/Input';
import Button from '../../custom/Button';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Controller, useForm } from 'react-hook-form';

export default function EditDesiredWorkTypeForm({control,errors,data}) {
  
  const readOnly = 0;
  return (
    <div className="flex flex-col mt-[40px] mb-[90px] gap-[20px] items-start, self-stretch m-auto">
      <div className="text-[20px] font-[600]">희망 근무 형태</div>
      <div className="flex w-[500px] tablet:w-full">
        {!readOnly?
        <Controller
          name="workType"
          control={control}
          render={({ field }) => (
            <Dropdown
              {...field}
              ref={undefined}
              options={[{value:"FULL",label:"풀타임 (주 40시간 이상)",className:"!pt-[14px] !pb-[15px]"},{value:"PART",label:"파트타임 (주 40시간 미만)",className:"!pt-[14px] !pb-[15px]"},{value:"ANY",label:"상관 없음", className:"!pt-[14px] !pb-[15px]"}]}
              value={data}
              className="rounded-lg border-[#B0B0BD] border-solid border-[1px] basis-[300px] tablet:grow"
              controlClassName="flex items-center !rounded-lg !border-0 !pt-[14px] !pr-[15px] !pb-[15px] !pl-[15px]"
              placeholderClassName="grow text-center"
              menuClassName="!m-0 text-center"
              arrowClassName="!top-[25px] !right-[20px]"
              onChange={(option) => field.onChange(option.value)}
              name="workType"
              placeholder="선택"
            />
          )}
        />
        :
        <Dropdown
          options={[{value:"FULL",label:"풀타임 (주 40시간 이상)",className:"!pt-[14px] !pb-[15px]"},{value:"PART",label:"파트타임 (주 40시간 미만)",className:"!pt-[14px] !pb-[15px]"},{value:"ANY",label:"상관 없음", className:"!pt-[14px] !pb-[15px]"}]}
          value={{value:data,label:valueLabelMap[data]}} disabled
          className="rounded-lg border-[#B0B0BD] border-solid border-[1px] basis-[300px] tablet:grow"
          controlClassName="!bg-gray-50 flex items-center !rounded-lg !border-0 !pt-[14px] !pr-[15px] !pb-[15px] !pl-[15px]"
          placeholderClassName="grow text-center"
          menuClassName="!m-0 text-center"
          arrowClassName="!top-[25px] !right-[20px]"
          name="workType"
          placeholder="선택"
        />
    }
      </div>
      {errors?.workType && (
        <div className="error">{errors?.workType.message}</div>
      )}
    </div>
    )
  }

  const valueLabelMap = {
    FULL : "풀타임 (주 40시간 이상)",
    PART : "파트타임 (주 40시간 미만)",
    ANY : "상관없음"
  }