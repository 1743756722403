import { Outlet } from 'react-router-dom';
import Footer from '../../components/Footer';
import HeaderWrapper, { HomeHeader } from '../../components/Header';
import LoginModal from '../../components/LoginModal';

export default function RootWrapper({ children }) {
  return (
    <div className="flex flex-col h-[100vh]">
      <LoginModal />
      <HeaderWrapper element={<HomeHeader />} />
      <div className="flex flex-col items-start gap-[30px] self-stretch w-[1000px] tablet:w-[100%] m-auto  flex-1">
        <div className="flex-1 w-full tablet:px-[10px]">
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  );
}
