
import Button from '../../custom/Button';
import {useState, useEffect, useCallback} from 'react';
import Checkbox from '../../custom/Checkbox';


export default function AgreementForm({agreements, setAgreedAgreementIds, agreementErrors, data}) {
  
  const readOnly = (setAgreedAgreementIds == null || agreementErrors == null) || data!=null;

  const onOneChecked = useCallback((id,checked)=>{
    if (checked) {
      setAgreedAgreementIds((ids)=>{ids.add(id); return ids;})
    } else {
      console.log(checked, setAgreedAgreementIds)
      setAgreedAgreementIds((ids)=>{ids.delete(id); return ids;})
    }
  })

  
  
  return (
    <div className="flex flex-col mt-[40px] mb-[90px] gap-[20px] items-start, self-stretch m-auto">
            <div className='flex items-center gap-[10px]'>
              <div className="text-[20px] font-[600]
                              grow
                              tablet:basis-[120px] tablet:shrink-0">
                개인정보 수집 및 이용안내에 동의합니다.
              </div>
              {/* <div className="font-[300]">희망하는 지역으로 최대한 매칭해드릴게요 :)</div> */}
            </div>
            <div className="flex flex-col gap-[10px]
                            tablet:w-full">
              {agreements.map((item,idx)=>(
                !readOnly?
                <AgreementFormItem
                  key={item.id}
                  data={item}
                  onOneChecked={onOneChecked}
                  error={agreementErrors[item.id]}
                />
                :
                <AgreementFormItem
                  key={item.id}  
                  data={item}
                  readData={agreements}
                />
              ))}
            </div>
          </div>
    )
  }

  const AgreementFormItem = ({data:{id, title,contentUrl}, onOneChecked, error, readData})=>{
   
    const onChange = useCallback((e)=>{
      if (e.target.checked) {
        onOneChecked(id,true) 
      } else {
        onOneChecked(id,false)
      }
    })
    return (
      <>
        <div className='flex items-center 
          text-[18px] gap-[10px] tablet:text-[16px]'>
          <Checkbox
            onChange={onChange}
            disabled={readData!=null}
            checked={readData==null ? undefined:readData.includes(id)}
          />
          <div>{title}</div>
          <a href={contentUrl} target="_blank" rel="noopener noreferrer"
            className='tablet:basis-[90px] tablet:shrink-0 '
          > 
            <Button
              className='tablet:text-[16px]'
              type="button"
            >
              전문보기    
            </Button>
          </a>
        </div>
        {error && (
              <div className="error">{error}</div>
        )}
      </>
    )
  }