import { useState, useCallback } from 'react';

export default function useItemValidation(educations,workExperiences,certificates) {
    const [itemsError, setItemsError] = useState({
        educations: {},
        workExperiences: {},
        certificates: {},
    });
    
    const validateEducations = useCallback(() => {
    if (educations.length === 0) {
        return true;
    }
    let hasFinal = false;
    let hasError = false;

    setItemsError((itemsError) => ({ ...itemsError, educations: {} }));
    for (let i = 0; i < educations.length; i++) {
        const {
        content: { type, status, name, isFinal, major },
        id,
        } = educations[i];

        if (!type || !status) {
            // if (
            //     educations.length === 1 && // 1개가 빈칸일 경우
            //     !name &&
            //     !status &&
            //     !type &&
            //     !major
            //     // && !isFinal // 학력 추가 옵션이 없을 경우 주석처리
            // ) {
            //     return true;
            // } // 학력 추가 옵션이 없을 경우 if 문을 주석처리
        
            setItemsError((itemsError) => ({
                ...itemsError,
                educations: {
                ...itemsError.educations,
                // [id]: '학력을 모두 입력해주세요 (학교명, 전공은 생략가능). 입력을 원치 않으실 경우 삭제해주세요.',
                [id]: '학력을 입력해주세요 (전공은 생략가능).', // 최종학력만 입력할 경우
            }}));
            hasError = true;
        } else if (
            type.length > 100 || (name && name.length > 100) || status.length > 100 ||
            (major && major.length >100) 
            ) {
            setItemsError((itemsError) => ({
                ...itemsError,
                educations: {
                ...itemsError.educations,
                [id]: '입력이 100자를 넘을 수 없습니다.',
            }}));
            hasError=true;
        }

        if (isFinal) {
            if (hasFinal) {
                setItemsError((itemsError) => ({
                ...itemsError,
                educations: {
                    ...itemsError.educations,
                    group:
                    '최종학력은 한 번만 체크할 수 있습니다. 마지막 학력에만 체크해주세요.',
                },
                }));
                hasError = true;
            } else {
                hasFinal = true;
            }
        }
    }
    if (!hasFinal) {
        setItemsError((itemsError) => ({
        ...itemsError,
        educations: {
            ...itemsError.educations,
            group: '최종학력을 체크해주세요.',
        },
        }));
        hasError = true;
    }
    return !hasError;
    }, [itemsError, educations]);

    const validateWorkExperiences = useCallback(() => {
        if (workExperiences.length === 0) {
            return true;
        }
        let hasError = false;
        setItemsError((itemsError) => ({ ...itemsError, workExperiences: {} }));
        workExperiences.forEach(
            (
            {
                content: { type, name, startYear, startMonth, endYear, endMonth },
                id,
            },
            index,
            ) => {
            if (
                !type ||
                !name ||
                !startYear ||
                !startMonth ||
                !endYear ||
                !endMonth
            ) {
                if (
                workExperiences.length === 1 &&
                !type &&
                !name &&
                !startYear &&
                !startMonth &&
                !endYear &&
                !endMonth
                ) {
                return true;
                }
                setItemsError((itemsError) => ({
                ...itemsError,
                workExperiences: {
                    ...itemsError.workExperiences,
                    [id]: '모든 항목을 입력해주세요. 입력을 원치 않으실 경우 삭제해주세요.',
                }
                }));
                hasError = true;
            } else if (
                startYear > endYear ||
                (startYear === endYear && startMonth > endMonth)
            ) {
                setItemsError((itemsError) => ({
                ...itemsError,
                workExperiences: {
                    ...itemsError.workExperiences,
                    [id]: '경력 기간을 확인해 주세요',
                }}));
                hasError = true;
            } else if (type.length > 100 || name.length > 100) {
                setItemsError((itemsError) => ({
                ...itemsError,
                workExperiences: {
                    ...itemsError.workExperiences,
                    [id]: '입력이 100자를 넘을 수 없습니다.',
                }}));
                hasError=true;
            }
            },
        );
        return !hasError;
    }, [itemsError, workExperiences]);

    const validateCertificates = useCallback(() => {
    if (certificates.length === 0) {
        return true;
    }
    let hasError = false;
    setItemsError((itemsError) => ({ ...itemsError, certificates: {} }));
    certificates.forEach(
        (
        { content: { type, name, acquisitionYear, acquisitionMonth }, id },
        index,
        ) => {
        if (!type || !name || !acquisitionYear || !acquisitionMonth) {
            if (
            certificates.length === 1 &&
            !type &&
            !name &&
            !acquisitionYear &&
            !acquisitionMonth
            ) {
            return true;
            }
            setItemsError((itemsError) => ({
            ...itemsError,
            certificates: {
                ...itemsError.certificates,
                [id]: '모든 항목을 입력해주세요. 입력을 원치 않으실 경우 삭제해주세요.',
            },
            }));
            hasError = true;
        } else if (type.length > 100 || name.length > 100) {
            setItemsError((itemsError) => ({
            ...itemsError,
            certificates: {
                ...itemsError.certificates,
                [id]: '입력이 100자를 넘을 수 없습니다.',
            }}));
            hasError=true;
        }
        },
    );
    return !hasError;
    }, [itemsError, certificates]);

    return {
        itemsError,
        validateEducations, validateWorkExperiences, validateCertificates 
    }
}