import Input from '../../custom/Input';
import { Controller} from 'react-hook-form';

export default function EmailForm({control,errors, data}) {
    const readOnly = (control == null) || data!=null;
    return (
    <div className="flex items-center">
      <div className="w-[105px] shrink-0 font-[400]">이메일</div>
      {!readOnly ?
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            autoComplete="off"
            className="tablet:text-[16px]"
            placeholder="이메일을 입력해 주세요."
            error={errors?.email}
          />
        )}
      />
      :
      <Input
        value={data} disabled
        autoComplete="off"
        className="tablet:text-[16px]"
        placeholder="이메일을 입력해 주세요."
        error={errors?.email}
      />
      }
    </div>
    );
}