import { useNavigate } from 'react-router-dom';
import { KAKAO_LOGIN_URL } from '../api';

import { connect, useDispatch, useSelector } from 'react-redux';
import CustomModal from '../custom/CustomModal';
import { close, selectLoginModal } from '../reducers/loginModal/loginModalReducer';

function LoginModal() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(selectLoginModal);

  const closeModal = () => {
    dispatch(close());
  };

  const onSignup = () => {
    navigate('/signup/agreement');
  }

  return (
    <>
    <CustomModal
        // ariaHideApp={true}
        // style={{
        //   overlay: {
        //     position: 'fixed',
        //     display: 'flex',
        //     zIndex: '1020',
        //     flexShrink: '0',
        //     alignItems: 'center',
        //     justifyContent: 'center',
        //     margin: 'auto',
        //     backgroundColor: 'rgba(0, 0, 0, 0.5)',
        //   },
        //   content: {
        //     display: 'flex',
        //     width: '500px',
        //     position: 'relative',
        //     zIndex: '1030',
        //     padding: '20px 16px 60px 16px',
        //     flexDirection: 'column',
        //     alignItems: 'center',
        //     gap: '70px',
        //     borderRadius: '8px',
        //   },
        // }}
        overlayClassName="fixed top-0 left-0 right-0 bottom-0 flex z-[1020] shrink-0 items-center justify-center m-auto bg-black/50"
        className="flex w-[500px] tablet:w-[300px] relative z-[1030] px-[16px] pt-[20px] pb-[60px] flex-col items-center gap-[70px] rounded-[8px] bg-[#FFF] overflow-auto"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className="flex w-[100%] h-[30px] justify-between text-center text-[22px] font-[600] text-[#111111]">
          <div className="w-[30px] h-[30px]"></div>{' '}
          {/* 정렬맞추기 위해 넣은 요소 */}
          <div className="flex-1">로그인</div>
          <ModalXButton onClick={closeModal} />
        </div>
        <div className="flex flex-col items-center gap-[20px]">
          <img
            className="flex w-[212px] py-[10px] px-[8px] justify-center items-center"
            src="/images/HIGHER-re.svg"
            alt="회사 로고"
          />
          {/* <div className="flex flex-col gap-[10px]">
            <Input placeholder="아이디" />
            <Input type="password" placeholder="비밀번호" />
            <div className="flex flex-wrap w-[360px] py-[5px] px-0 justify-between items-center content-center gap-y-[15px] text-[#111111]">
              <div className="flex w-[128px] items-center flex-shrink-0 gap-[10px]">
                <Checkbox />
                <div className="text-[16px] font-[400] leading-normal">
                  자동로그인
                </div>
              </div>
            </div>
            <Button className="w-[360px] h-[60px] bg-[#440FDC] text-white text-[20px] font-[600]">로그인</Button>
            <Button className="w-[360px] h-[60px] text-[20px] font-[600]" onClick={onSignup}>회원가입</Button>
          </div>
          <div className="text-center text-[16px] font-normal text-[#726ACA] leading-normal not-italic">아이디/비밀번호를 잊으셨나요?</div> */}
          <img src="/images/img_resume.svg" />
        </div>
        <a href={process.env.REACT_APP_API_URL + KAKAO_LOGIN_URL}>
          <button className="flex w-[360px] tablet:w-[280px] h-[60px] py-[12px] justify-center items-center gap-[10px] rounded-[9px] bg-[#FFDF00]">
            <img src="/images/icon_kakao.svg" alt="로고" />
            <div className="text-[18px] font-normal leading-normal">카카오로 로그인 / 회원가입</div>
          </button>
        </a>
      </CustomModal>
    </>
  );
}

function ModalXButton({ onClick }) {
  return (
    <button onClick={onClick} className="w-[30px] h-[30px]">
      <img src="/images/btn_close.svg" alt="x" />
    </button>
  );
}

const mapStateToProps = function(state) {
  return {
    modalIsOpen: state.loginModal.isOpen
  }
}

export default connect(mapStateToProps)(LoginModal);
