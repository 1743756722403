import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactModal from 'react-modal';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import './index.css';
import AuthProvider from './providers/AuthProvider';
import CustomToastProvider from './providers/CustomToastProvider';
import store from './reducers/store';
import reportWebVitals from './reportWebVitals';
import router from './router';
import { CookiesProvider } from 'react-cookie';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <CustomToastProvider>
          <Provider store={store}>
            <AuthProvider>
              <RouterProvider router={router} />
            </AuthProvider>
          </Provider>
      </CustomToastProvider>
    </CookiesProvider>
  </React.StrictMode>,
);

ReactModal.setAppElement('#root')

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
