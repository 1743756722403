import React, {useState} from 'react';
import Input from '../../custom/Input';
import Button from '../../custom/Button';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Controller, useForm } from 'react-hook-form';

export default function DesiredWorkRegionForm({control,errors,data }) {
  const readOnly = (control == null) || data!=null;
  const regionOptions = ["서울특별시","부산광역시","대구광역시","인천광역시","광주광역시","대전광역시","울산광역시","세종특별자치시","경기도","충청북도","충청남도","전라북도","전라남도","경상북도","경상남도","강원특별자치도","제주특별자치도"]
    .map((item)=>({value:item,lable:item,className:"!pt-[14px] !pb-[15px]"}))
  const [regionCityOptions,setRegionCityOptions] = useState([]);

  

  return (
    <div className="flex flex-col mt-[40px] mb-[90px] gap-[20px] items-start, self-stretch m-auto">
            <div className='flex items-center gap-[10px]'>
              <div className="text-[20px] font-[600] tablet:basis-[120px] tablet:shrink-0">희망 근무 지역</div>
              <div className="font-[300]">희망하는 지역으로 최대한 매칭해드릴게요 :)</div>
            </div>
            <div className="flex gap-[10px]
                            tablet:w-full tablet:flex-col">
              
              {!readOnly?
                <Controller
                  name="workRegion"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      ref={undefined}
                      options={regionOptions}
                      value=""
                      className="basis-[300px] self-start shrink-0 
                                tablet:basis-0 tablet:self-stretch
                                rounded-lg border-[#B0B0BD] border-solid border-[1px]"
                      controlClassName="flex items-center !rounded-lg !border-0 !pt-[14px] !pr-[15px] !pb-[15px] !pl-[15px]"
                      placeholderClassName="grow text-center"
                      menuClassName="!m-0 text-center"
                      arrowClassName="!top-[25px] !right-[20px]"
                      onChange={(option) => {
                        setRegionCityOptions(regionCityMap[option.value]);
                        field.onChange(option.value);
                      }}
                      name="workRegion"
                      placeholder="선택"
                    />
                  )}
                />
                :
                <Dropdown
                  options={regionOptions}
                  value={{value:data.region , label:data.region}} disabled
                  className="basis-[300px] self-start shrink-0 
                            tablet:basis-0 tablet:self-stretch
                            rounded-lg border-[#B0B0BD] border-solid border-[1px]"
                  controlClassName="!bg-gray-50 flex items-center !rounded-lg !border-0 !pt-[14px] !pr-[15px] !pb-[15px] !pl-[15px]"
                  placeholderClassName="grow text-center"
                  menuClassName="!m-0 text-center"
                  arrowClassName="!top-[25px] !right-[20px]"
                  name="workRegion"
                  placeholder="선택"
                />
              }
              {/* {!readOnly?
              <Controller
                name="workRegionCity"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    // error={errors?.workRegionCity}
                    autoComplete="off"
                    className="tablet:text-[16px]"
                    placeholder="상세지역을 입력해주세요 (강남구, 성남시 등)" 
                  />
                )}
              />
              :
              <Input
                value={data.city} disabled
                autoComplete="off"
                className="tablet:text-[16px]"
                placeholder="상세지역을 입력해주세요 (강남구, 성남시 등)" 
              />
              } */}
              {!readOnly?
                <Controller
                  name="workRegionCity"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      ref={undefined}
                      options={regionCityOptions}
                      value=""
                      className="basis-[300px] self-start shrink-0 
                                tablet:basis-0 tablet:self-stretch
                                rounded-lg border-[#B0B0BD] border-solid border-[1px]"
                      controlClassName="flex items-center !rounded-lg !border-0 !pt-[14px] !pr-[15px] !pb-[15px] !pl-[15px]"
                      placeholderClassName="grow text-center"
                      menuClassName="!m-0 text-center"
                      arrowClassName="!top-[25px] !right-[20px]"
                      onChange={(option) => {
                        field.onChange(option.value);
                      }}
                      name="workRegionCity"
                      placeholder="선택"
                    />
                  )}
                />
                :
                <Dropdown
                  options={regionCityOptions}
                  value={{value:data.city , label:data.city}} disabled
                  className="basis-[300px] self-start shrink-0 
                            tablet:basis-0 tablet:self-stretch
                            rounded-lg border-[#B0B0BD] border-solid border-[1px]"
                  controlClassName="!bg-gray-50 flex items-center !rounded-lg !border-0 !pt-[14px] !pr-[15px] !pb-[15px] !pl-[15px]"
                  placeholderClassName="grow text-center"
                  menuClassName="!m-0 text-center"
                  arrowClassName="!top-[25px] !right-[20px]"
                  name="workRegionCity"
                  placeholder="선택"
                />
              }
            </div>
            { (errors?.workRegion || errors?.workRegionCity)  && (
              <div className="error">{errors?.workRegion?.message || errors?.workRegionCity?.message }</div>
            )}
            
          </div>
    )
  }



  const regionCityMap = {
    "서울특별시" : ["종로구", "중구", "용산구", "성동구", "광진구",
                "동대문구", "중랑구", "성북구", "강북구", "도봉구",
                "노원구", "은평구", "서대문구", "마포구", "양천구",
                "강서구", "구로구", "금천구", "영등포구", "동작구",
                "관악구", "서초구", "강남구", "송파구", "강동구"],
    "부산광역시" : ["중구", "서구", "동구", "영도구", "부산진구",
                "동래구", "남구", "북구", "해운대구", "사하구",
                "금정구", "강서구", "연제구", "수영구", "사상구",
                "기장군"],
    "대구광역시" : ["중구", "동구", "서구", "남구",
                "북구", "수성구", "달서구",
                "달성군", "군위군"],
    "인천광역시" : ["중구", "동구", "미추홀구", "연수구",
                "남동구", "부평구", "계양구", "서구",
                "강화군", "옹진군"],
    "광주광역시" : ["동구", "서구", "남구", "북구", "광산구"],
    "대전광역시" : ["동구", "중구", "서구", "유성구", "대덕구"],
    "울산광역시" : ["중구", "남구", "동구", "북구", "울주군"],
    "세종특별자치시" : ["세종특별자치시"],
    "경기도" : ["고양시", "수원시", "용인시",
              "과천시", "광명시", "광주시", "구리시", "군포시",
              "김포시", "남양주시", "동두천시", "부천시", "성남시",
              "시흥시", "안산시", "안성시", "안양시", "양주시",
              "여주시", "오산시", "의왕시", "의정부시", "이천시",
              "파주시", "평택시", "포천시", "하남시", "화성시",
              "가평군", "양평군", "연천군"],
    "충청북도" : ["제천시", "청주시", "충주시",
                "괴산군", "단양군", "보은군", "영동군",
                "옥천군", "음성군", "증평군", "진천군"], 
    "충청남도" : ["계롱시", "공주시", "논산시", "당진시",
                "보령시", "서산시", "아산시", "천안시",
                "금산군", "부여군", "서천군", "예산군",
                "청양군", "태안군", "홍성군"],
    "전라북도" : ["군산시", "김제시", "남원시", "익산시", "전주시", "정읍시",
                "고창군", "무주군", "부안군", "순창군", 
                "완주군", "임실군", "장수군", "진안군" ],
    "전라남도" : ["목포시", "여수시", "순천시", "나주시", "광양시",
                "담양군", "곡성군", "구례군", "고흥군", "보성군",
                "화순군", "장흥군", "강진군", "해남군", "영암군",
                "무안군", "함평군", "영광군", "장성군", "완도군",
                "진도군", "신안군"],
    "경상북도" : ["경산시", "경주시", "구미시", "김천시", "문경시",
                "상주시", "안동시", "영주시", "영천시", "포항시",
                "고령군", "봉화군", "성주군", "영덕군",
                "영양군", "예천군", "울릉군", "울진군",
                "의성군", "청도군", "청송군", "칠곡군"],
    "경상남도" : ["창원시", "거제시", "김해시", "밀양시",
                "사천시", "양산시", "진주시", "통영시",
                "거창군", "고성군", "남해군", "산청군", "의령군",
                "창녕군", "하동군", "함안군", "함양군","합천군"],
    "강원특별자치도" : ["강릉시", "동해시", "삼척시", "속초시",
                    "원주시", "춘천시", "태백시",
                    "고성군", "양구군", "양양군", "영월군",
                    "인제군", "정선군", "철원군", "평창군",
                    "홍천군", "화천군", "횡성군"],
    "제주특별자치도" : ["제주시", "서귀포시"],
  }