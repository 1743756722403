export default function Radio(props) {
  const new_props = {
    ...props,
    className: `${props.className} w-[30px] h-[30px] accent-[#440FDC]`
  }

  return (
    <label className="flex justify-center items-center">
      <input type="radio" {...new_props} />
    </label>
  )
}