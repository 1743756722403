import Input from '../../custom/Input';
import { Controller} from 'react-hook-form';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

export default function BirthYearForm({control,errors, currentUser}) {
    const readOnly = (control == null);
    return (
    <div className="flex items-center flex-1 rounded">
      <div className="w-[105px] shrink-0 font-[400]">출생년도</div>
      {!readOnly ?
      <Controller
        name="birthYear"
        control={control}
        render={({ field }) => (
          <Dropdown
            {...field}
            disabled
            options={yearOptions}
            value={{
              value: dateStringToYear(currentUser?.birthDate),
              label: `${dateStringToYear(currentUser?.birthDate)}`,
            }}
            className="flex rounded-lg border-[#B0B0BD] border-solid border-[1px] w-[360px] tablet:w-[208px]"
            controlClassName="!bg-gray-50 flex grow items-center !rounded-lg !border-0 !pt-[14px] !pr-[15px] !pb-[15px] !pl-[15px]"
            placeholderClassName="flex-1 text-center"
            menuClassName="!m-0 text-center"
            arrowClassName="!top-[25px] !right-[20px]"
            onChange={(option) => field.onChange(option.value)}
            placeholder="선택"
          />
        )}
      />
      :
      <Dropdown
            options={yearOptions}
            disabled
            value={{
              value: dateStringToYear(currentUser?.birthDateTime),
              label: `${dateStringToYear(currentUser?.birthDateTime)}`,
            }}
            className="flex rounded-lg border-[#B0B0BD] border-solid border-[1px] w-[360px] tablet:w-[208px]"
            controlClassName="!bg-gray-50 flex grow items-center !rounded-lg !border-0 !pt-[14px] !pr-[15px] !pb-[15px] !pl-[15px]"
            placeholderClassName="flex-1 text-center"
            menuClassName="!m-0 text-center"
            arrowClassName="!top-[25px] !right-[20px]"
            placeholder="선택"
          />
      }
    </div>
    );
}

const yearOptions = Array(110)
  .fill()
  .map((element, index) => 110 - index + 1900);


const dateStringToYear = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear();
};