import ReactPaginate from 'react-paginate';
import { getCurrentUserApplications } from '../../api';
import { useEffect, useState } from 'react';
import moment from 'moment';
import HeaderWrapper, { TitleHeader } from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import Button from '../../custom/Button'
import { deleteResumeWithApplication } from '../../api';
export default function MyPage() {
  const navigate = useNavigate();
  const [applications, setApplications] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    getCurrentUserApplications(page)
      .then((res) => {
        setApplications(res.data.content);
        setTotalPages(res.data.totalPages);
      })
      .catch((error) => console.log(error));
  }, [page]);

  const onPageChange = (event) => {
    setPage(event.selected);
  };

  const routeToResume = (resumeId) => {
    navigate('/resumes',{state:{resumeId:resumeId}});
  }

  const onDeleteResume = (resumeId) => {
    if( window.confirm("정말로 삭제하시겠습니까? 삭제된 이력서는 복구할 수 없으므로 삭제에 주의해주세요.")){
      deleteResumeWithApplication(resumeId)
        .then((res)=>{
          window.location.reload();
        }).catch((error) => console.log(error));
    }
  }

  const onEditResume = (resumeId) => {
    // 이력서 수정 페이지로 이동하는 함수
    console.log(resumeId)
    navigate(`/edit-resume/`, {state:{resumeId:resumeId}});
  };

  const menus = [
    {
      key: '1',
      renderCondition: true,
      elements: [<CheckMyInfoButton />],
    },
  ];

  return (
    <>
      <HeaderWrapper
        element={
          <TitleHeader title="마이페이지" hasSideMenu={true} menus={menus} />
        }
      />
      <div className="flex flex-row justify-between gap-[30px] m-auto w-[1000px] tablet:w-[100%] tablet:px-[20px] mt-[30px]">
        <div className="w-[710px]">
          <div className="text-[#111] text-[22px] font-[600] leading-[30px]">
            <div className="flex flex-row">
              <div className="flex cursor-pointer">
                <span className="inline text-[30px]">지원현황</span>{' '}
                <img src="images/left_arrow.svg" className="rotate-180" />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            {applications.map((application) => {
              return (
                <div
                  key={application?.id}
                  onClick={()=>routeToResume(application?.id)}
                  className="flex py-[25px] px-[10px] justify-between items-center content-center gap-[20px] self-stretch flex-wrap border-b-[1px] border-solid border-[#D2D2D2] w-[100%]
                            cursor-pointer"
                >
                  <div className="flex flex-col items-start gap-[10px]">
                    <div className="text-[18px] font-[600] text-[#726ACA]">
                      지원완료
                    </div>
                    <div className="text-[18px] font-[600]">
                      {application?.programName}
                    </div>
                    <div>
                      {application?.companyName} |{' '}
                      {moment(application?.applicationDateTime).format(
                        'yyyy.MM.DD',
                      )}{' '}
                      지원
                    </div>
                  </div>
                  <div className='self-end'>
                  <Button
                      className={"text-[13px] text-blue-500 px-[20px] py-[8px] "}
                      onClick={(e) => {e.stopPropagation(); onEditResume(application?.id);}}
                    >
                      수정 
                    </Button>                  
                    <Button
                      className={"text-[13px] text-red-500 px-[20px] py-[8px] "}
                      onClick={(e)=>{e.stopPropagation(); onDeleteResume(application?.id);}}
                    >
                      삭제 
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="w-full flex items-center justify-center text relative top-[50px]">
            <ReactPaginate
              className="flex items-center gap-[15px] mb-[50px]"
              previousLinkClassName={prevNextButtonClassName}
              nextLinkClassName={prevNextButtonClassName}
              pageLinkClassName="w-[46px] h-[46px] flex-[1_0_0] self-stretch rounded-[46px] flex items-center justify-center text-[#440FDC] select-none"
              activeLinkClassName="bg-[#F4F5FB]"
              nextLabel=">"
              previousLabel="<"
              pageCount={totalPages}
              pageRangeDisplayed={5}
              marginPagesDisplayed={1}
              onPageChange={onPageChange}
            />
          </div>
        </div>
        <div className="w-[260px] tablet:hidden">
          <div className="border-solid border-[#D3D3DF] rounded-[8px] w-[260px] border-[1px] py-[10px] pr-[10px] pl-[15px]">
            <CheckMyInfoButton />
          </div>
        </div>
      </div>
    </>
  );
}

function CheckMyInfoButton() {
  const navigate = useNavigate();

  const goToMyInfo = () => {
    navigate('/myPage/myInfo');
  };

  return (
    <div
      onClick={goToMyInfo}
      className="flex justify-between items-center  cursor-pointer"
    >
      <div>내 정보 확인</div>
      <div>
        <img className="rotate-180" src="images/left_arrow.svg" />
      </div>
    </div>
  );
}

const prevNextButtonClassName =
  'flex w-[40px] h-[50px] p-[10px] justify-center items-center gap-[10px] rounded-[10px] border-[1px] border-[#D3D3DF] bg-white select-none';
