import Input from '../../custom/Input';
import Button from '../../custom/Button';
import Checkbox from '../../custom/Checkbox';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

export default function ResumeEducaitonForm({items, onCreate, onChange, onDelete, categories, itemsError}){
  const readOnly = onCreate == null || onChange == null || onDelete == null || itemsError == null
  return (
    <div className="flex mt-[40px] flex-col gap-[20px] items-start, self-stretch, m-auto">
      <div className="flex items-center">
        <div className="text-[20px] font-[600]">최종학력</div>
        {itemsError?.educations?.group && (
          <div className="ml-[30px] text-red-600">
            {itemsError.educations.group}
          </div>
        )}
      </div>
      {items.map((item,idx) => (
        !readOnly ?
        <ResumeEducationItem
          id={item.id}
          key={item.id}
          content={item.content}
          onEducationChange={onChange}
          onEducationDelete={onDelete}
          category={categories}
          canDelete={false}
          error={itemsError?.educations[item.id]}
        />
        :
        <ResumeEducationItem
          id={idx}
          key={idx}
          readContent={item}
          category={categories}
        />
      ))}
      {/* <Button
        type="button"
        onClick={onCreate}
        className="px-0 flex-1 border-0 bg-[#F4F5FB] text-[#440FDC] font-[600] text-[18px]"
      >
        추가 +
            </Button> */}
    </div>
  );
}


function ResumeEducationItem({id, content, onEducationChange, onEducationDelete, category, error, readContent}) {
  
  const readOnly = readContent != null
  const optionClassName=''
  const educationOptions = category.map((item)=>({value:item,className:optionClassName}));
  const statusOptions = [
    {value: "졸업", className : optionClassName},
    {value: "중퇴", className : optionClassName},
    {value: "휴학", className : optionClassName},
    {value: "재학", className :optionClassName},
  ]
  
  return(
    <>
    <div className={`w-full text-[18px] tablet:text-[16px] flex flex-col items-stretch  ${error && "!border-red-600 border-solid border-[1px] rounded-lg"}`}>

      <div className='flex items-stretch w-1/2 tablet:w-full'>
        
        <div className='basis-[105px] shrink-0
                        flex items-center
                        pl-[15px] font-[400]
                        rounded-tl-lg border-[#B0B0BD] border-solid border-[1px] border-b-0 border-r-0
                        tablet:basis-[55px] tablet:pl-[12px]'>    
            학력
        </div>

        <div className='grow shrink-0 
                        flex items-center 
                        border-[#B0B0BD] border-[1px] border-solid border-l-0 border-b-0 rounded-tr-lg'>
          <Dropdown 
              options={educationOptions} 
              value = {!readOnly ? undefined : {value:readContent.degree,label:readContent.degree}} 
              disabled = {readOnly}
              className={`grow basis-[100px] shrink-0`}
              controlClassName={'flex items-center !border-0 !pt-[14px] !pr-[15px] !pb-[15px] !pl-[15px]' + (readOnly ? " !bg-gray-50":"")}
              placeholderClassName='flex-1 text-center'
              menuClassName='!m-0 text-center'
              arrowClassName='!top-[25px] !right-[20px] tablet:!right-[10px]'
              onChange={(option)=>onEducationChange({target:{...option,name:"type"}},id)}
              placeholder="선택" />
          <Dropdown
            options={statusOptions}
            value = {!readOnly ? undefined : {value:readContent.status,label:readContent.status}} 
            disabled = {readOnly}
            className='grow basis-[100px] shrink-0'
            controlClassName={'!pt-[14px] !pr-[15px] !pb-[15px] !pl-[15px] !border-0 !rounded-tr-lg !border-l-[#B0B0BD] !border-l-[1px]' + (readOnly ? " !bg-gray-50":"") }
            placeholderClassName='text-center'
            menuClassName='!m-0 text-center' 
            arrowClassName='!top-[25px] !right-[20px] tablet:!right-[10px]'
            onChange={(option)=>onEducationChange({target:{...option,name:"status"}},id)}
            placeholder="상태 선택"
          />
        </div>



        {/* <div className='flex mr-auto ml-[20px]'>
          <div className='font-[400] pr-[20px]'>
            최종학력 여부
          </div>
          <Checkbox
            onChange={(e)=>onEducationChange({target:{value : !(content?.isFinal) ,name:"isFinal"}},id)}
            checked={content?.isFinal}
          />
        </div> */}
      </div>
      <div >
        <div className='flex'>
          <div className='flex-1 border-[#B0B0BD] border-solid border-[1px] rounded-b-lg rounded-tr-lg tablet:rounded-tr-none'>
            {/* <div className='w-full flex items-center border-b-[#B0B0BD] border-b-solid border-b-[1px] '>
                <div className='w-[105px] pl-[15px] font-[400]
                              tablet:basis-[80px] shrink-0 tablet:px-[12px]'>
                  학교명
                </div>
                <Input
                  value={!readOnly? content?.name : readContent.school}
                  disabled={readOnly}
                  placeholder='학교명을 입력해주세요. (생략가능)' 
                  wrapperClassName='grow' className='border-0 w-full tablet:text-[16px]'
                  autoComplete="off" 
                  name="name" onChange={(e)=>onEducationChange(e,id)} >
                </Input>
            </div> */}
            <div className='w-full flex items-center w-full'>
                <div className='w-[105px] pl-[15px] font-[400]
                                tablet:basis-[80px] shrink-0 tablet:px-[12px]'>
                  전공
                </div>
                <Input
                  value={!readOnly? content?.major : readContent.major}
                  disabled={readOnly}
                  placeholder='전공을 입력해주세요. (생략가능)'
                  wrapperClassName='grow' className='border-0 w-full tablet:text-[16px]'  
                  autoComplete="off" 
                  name="major" onChange={(e)=>onEducationChange(e,id)}>
                </Input>
            </div>
          </div>
          
          {/* <Button
            type='button' 
            onClick={()=>onEducationDelete(id)}
            className=' rounded-l-none border-l-0 basis-[60px] border-[#B0B0BD] bg-[#440FDC] text-white text-[15px]'
            >
            삭제
          </Button> */}
        </div>
      </div>
    </div>
    {error && <div className="text-red-600">{error}</div>}
    </>
    )
  }